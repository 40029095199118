import React, { useState, useEffect } from 'react';
import { Calendar, momentLocalizer } from 'react-big-calendar';
import moment from 'moment';
import 'react-big-calendar/lib/css/react-big-calendar.css';

const localizer = momentLocalizer(moment);

const CalendarPage = () => {
    const [events, setEvents] = useState([]);
    const [selectedEvent, setSelectedEvent] = useState(null);

    useEffect(() => {
        fetchEvents();
    }, []);

    const fetchEvents = async () => {
        // Simulated API call
        const response = await fetch('/api/events');
        const data = await response.json();
        setEvents(data.map(event => ({
            ...event,
            start: new Date(event.start),
            end: new Date(event.end)
        })));
    };

    const handleSelectSlot = ({ start, end }) => {
        const title = window.prompt('New event name');
        if (title) {
            const newEvent = { title, start, end };
            setEvents([...events, newEvent]);
            // Here you would typically send this to your backend
        }
    };

    const handleSelectEvent = (event) => {
        setSelectedEvent(event);
    };

    const EventDetails = ({ event }) => (
        <div className="event-details">
            <h3>{event.title}</h3>
            <p>Start: {moment(event.start).format('MMMM Do YYYY, h:mm a')}</p>
            <p>End: {moment(event.end).format('MMMM Do YYYY, h:mm a')}</p>
            <p>{event.description}</p>
        </div>
    );

    return (
        <div className="calendar-page">
            <h1>Company Calendar</h1>
            <div className="calendar-container">
                <Calendar
                    localizer={localizer}
                    events={events}
                    startAccessor="start"
                    endAccessor="end"
                    style={{ height: 500 }}
                    onSelectSlot={handleSelectSlot}
                    onSelectEvent={handleSelectEvent}
                    selectable
                />
            </div>
            {selectedEvent && <EventDetails event={selectedEvent} />}
        </div>
    );
};

export default CalendarPage;