import React, { useState } from 'react';
import { Link } from 'react-router-dom';
import '../styles/Careers.css';
import logo from '../assets/favicon.png'; // Ensure to have a logo in the assets folder
import value1 from '../assets/value1.jpg'; // Replace with actual image paths
import value2 from '../assets/value2.png';
import value3 from '../assets/value3.jpg';
import value4 from '../assets/value4.jpg';

const jobPostings = [
    {
        id: 1,
        title: "Software Engineer intern",
        location: "San Francisco, CA, India (Remote)",
        description: "Develop and maintain software applications, collaborating with cross-functional teams to deliver high-quality products."
    },
    {
        id: 2,
        title: "Data Scientist intern",
        location: "New York, NY, India (Remote)",
        description: "Analyze complex data sets to provide insights and drive data-driven decision-making across the organization."
    },
    {
        id: 3,
        title: "Project Management intern",
        location: "Remote",
        description: "Lead product development from ideation to launch, ensuring alignment with company goals and customer needs."
    },
    {
        id: 4,
        title: "Digital Marketing intern",
        location: "Austin, TX, India (Remote)",
        description: "Create and execute digital marketing campaigns, analyze performance metrics, and optimize strategies to drive customer engagement and brand awareness."
    },
    {
        id: 5,
        title: "Business Analyst intern",
        location: "Austin, TX, India (Remote)",
        description: "Analyze business processes, gather requirements, and provide insights to support data-driven decision-making and improve operational efficiency."
    },
    {
        id: 6,
        title: "Human Resources (HR) Manager intern",
        location: "Los Angeles, CA, India (Remote)",
        description: "Assist in recruitment, employee relations, and administrative tasks to support the HR department."
    },
    {
        id: 7,
        title: "DevOps Engineer intern",
        location: "Seattle, WA, India (Remote)",
        description: "Manage and automate deployment processes, ensuring smooth operation of software applications in production."
    },
    {
        id: 8,
        title: "Backend Engineer intern",
        location: "Remote",
        description: "Develop server-side logic, ensuring high performance and responsiveness to requests from the front-end."
    },
    {
        id: 9,
        title: "Technical Writer intern",
        location: "Boston, MA, India (Remote)",
        description: "Create and maintain documentation for software applications, ensuring clarity and accessibility for users and developers."
    },
    {
        id: 10,
        title: "Quality Assurance (QA) Engineer intern",
        location: "Chicago, IL, India (Remote)",
        description: "Test and ensure the quality of software applications, identifying and reporting bugs to the development team."
    }
];

const Careers = () => {
    const [currentPage, setCurrentPage] = useState(1);
    const [searchRole, setSearchRole] = useState("");
    const [searchLocation, setSearchLocation] = useState("");
    const [showSuggestions, setShowSuggestions] = useState(false);

    const jobsPerPage = 6;

    const filteredJobs = jobPostings.filter(job =>
        (job.title.toLowerCase().includes(searchRole.toLowerCase()) || job.location.toLowerCase().includes(searchLocation.toLowerCase()))
    );

    const indexOfLastJob = currentPage * jobsPerPage;
    const indexOfFirstJob = indexOfLastJob - jobsPerPage;
    const currentJobs = filteredJobs.slice(indexOfFirstJob, indexOfLastJob);

    const totalPages = Math.ceil(filteredJobs.length / jobsPerPage);

    const handleNextPage = () => {
        if (currentPage < totalPages) {
            setCurrentPage(currentPage + 1);
        }
    };

    const handlePreviousPage = () => {
        if (currentPage > 1) {
            setCurrentPage(currentPage - 1);
        }
    };

    const handleSearchRoleChange = (e) => {
        setSearchRole(e.target.value);
        setShowSuggestions(true);
    };

    const handleSearchLocationChange = (e) => {
        setSearchLocation(e.target.value);
        setShowSuggestions(true);
    };

    const suggestions = jobPostings.filter(job =>
        job.title.toLowerCase().includes(searchRole.toLowerCase()) || job.location.toLowerCase().includes(searchLocation.toLowerCase())
    );

    return (
        <div className="careers-page">
            <header className="careers-header">
                <img src={logo} alt="Pilomation Logo" className="logo"/>
                <div className="header-text">
                    <h1>Welcome to Pilomation Careers</h1>
                    <p>Find your dream job and join our innovative team!</p>
                </div>
            </header>
            
            <div className="job-search-bar">
                <div className="search-box">
                    <input
                        type="text"
                        placeholder="Search job profiles"
                        value={searchRole}
                        onChange={handleSearchRoleChange}
                    />
                    {showSuggestions && searchRole && (
                        <div className="suggestions">
                            {suggestions
                                .filter(job => job.title.toLowerCase().includes(searchRole.toLowerCase()))
                                .map((suggestion, index) => (
                                    <Link
                                        key={index}
                                        className="suggestion"
                                        to={`/careers/${suggestion.id}`}
                                        onClick={() => setShowSuggestions(false)}
                                    >
                                        {suggestion.title}
                                    </Link>
                                ))
                            }
                        </div>
                    )}
                </div>
                <div className="search-box">
                    <input
                        type="text"
                        placeholder="Search locations"
                        value={searchLocation}
                        onChange={handleSearchLocationChange}
                    />
                    {showSuggestions && searchLocation && (
                        <div className="suggestions">
                            {suggestions
                                .filter(job => job.location.toLowerCase().includes(searchLocation.toLowerCase()))
                                .map((suggestion, index) => (
                                    <Link
                                        key={index}
                                        className="suggestion"
                                        to={`/careers/${suggestion.id}`}
                                        onClick={() => setShowSuggestions(false)}
                                    >
                                        {suggestion.location}
                                    </Link>
                                ))
                            }
                        </div>
                    )}
                </div>
            </div>
            
            <div className="life-at-pilomation">
                <h1>Life at Pilomation</h1>
                <p className="slogan">Innovating the future, together.</p>
                <p className="intro-text">
                    At Pilomation, we are dedicated to fostering an environment where creativity and technology come together to create impactful solutions.
                </p>
            </div>
            
            <div className="our-values">
                <div className="value-card">
                    <Link to="/BlogDisplay/benefits" className="value-link">
                        <img src={value1} alt="Benefits"/>
                        <h3>Benefits</h3>
                        <p>Discover a comprehensive benefits package that supports your well-being and professional growth.</p>
                    </Link>
                </div>
                <div className="value-card">
                    <Link to="/blogs/culture" className="value-link">
                        <img src={value2} alt="Culture"/>
                        <h3>Culture</h3>
                        <p>Experience a vibrant and inclusive culture that encourages collaboration and celebrates diversity.</p>
                    </Link>
                </div>
                <div className="value-card">
                    <Link to="/blogs/unity-in-diversity" className="value-link">
                        <img src={value3} alt="Unity in Diversity"/>
                        <h3>Unity in Diversity</h3>
                        <p>Embrace our commitment to fostering an inclusive environment that values and respects individual differences.</p>
                    </Link>
                </div>
                <div className="value-card">
                    <Link to="/blogs/globebox-environment" className="value-link">
                        <img src={value4} alt="GlobeBox Environment"/>
                        <h3>GlobeBox Environment</h3>
                        <p>Join us in creating a sustainable future by working in an environmentally conscious workplace.</p>
                    </Link>
                </div>
            </div>
            
            
            <div className="job-postings">
                <h2>Current Job Postings</h2>
                <div className="job-grid">
                    {currentJobs.map((job) => (
                        <Link to={`/careers/${job.id}`} key={job.id} className="job-card">
                            <h3>{job.title}</h3>
                            <p><strong>Location:</strong> {job.location}</p>
                            <p>{job.description}</p>
                        </Link>
                    ))}
                </div>
                <div className="pagination">
                    <button onClick={handlePreviousPage} disabled={currentPage === 1}>Previous</button>
                    <button onClick={handleNextPage} disabled={currentPage === totalPages}>Next</button>
                </div>
            </div>

            <footer className="careers-footer">
                <img src={logo} alt="Pilomation Logo" className="footer-logo"/>
                <p>© 2024 Pilomation</p>
            </footer>
        </div>
    );
};

export default Careers;
