import React, { useState } from 'react';
import { Link } from 'react-router-dom';
import '../styles/TermsOfService.css'; 

const TermsOfService = () => {
    const [activeSection, setActiveSection] = useState(null);

    const termsData = [
        {
            title: "Acceptance of Terms",
            content: "By accessing or using Pilomation's services, you agree to be bound by these Terms of Service. If you disagree with any part of the terms, you may not access our services."
        },
        {
            title: "Use of Services",
            content: "Our services are provided 'as is'. You may use our services only as permitted by law and these Terms. Pilomation reserves the right to modify or discontinue any part of our services without notice."
        },
        {
            title: "User Accounts",
            content: "You are responsible for safeguarding the password used to access our services and for any activities or actions under your account. You must immediately notify Pilomation of any unauthorized use of your account."
        },
        {
            title: "Intellectual Property",
            content: "The service and its original content, features, and functionality are and will remain the exclusive property of Pilomation and its licensors. Our trademarks and trade dress may not be used in connection with any product or service without the prior written consent of Pilomation."
        },
        {
            title: "User-Generated Content",
            content: "You retain any copyright that you may hold in the content you submit through our services. By submitting content, you grant Pilomation a worldwide, non-exclusive, royalty-free license to use, reproduce, modify, and distribute that content in connection with the services."
        },
        {
            title: "Termination",
            content: "We may terminate or suspend access to our services immediately, without prior notice or liability, for any reason whatsoever, including without limitation if you breach the Terms. All provisions of the Terms which by their nature should survive termination shall survive termination."
        },
        {
            title: "Limitation of Liability",
            content: "In no event shall Pilomation, nor its directors, employees, partners, agents, suppliers, or affiliates, be liable for any indirect, incidental, special, consequential or punitive damages, including without limitation, loss of profits, data, use, goodwill, or other intangible losses, resulting from your access to or use of or inability to access or use the services."
        },
        {
            title: "Governing Law",
            content: "These Terms shall be governed and construed in accordance with the laws of [Your Jurisdiction], without regard to its conflict of law provisions."
        },
        {
            title: "Changes to Terms",
            content: "We reserve the right, at our sole discretion, to modify or replace these Terms at any time. By continuing to access or use our services after those revisions become effective, you agree to be bound by the revised terms."
        }
    ];

    const toggleSection = (index) => {
        setActiveSection(activeSection === index ? null : index);
    };

    return (
        <div className="terms-of-service-container">
            <h1>Pilomation Terms of Service</h1>
            <p className="last-updated">Last Updated: June 28, 2024</p>

            <div className="terms-sections">
                {termsData.map((section, index) => (
                    <div key={index} className="terms-section">
                        <h2 onClick={() => toggleSection(index)}>
                            {section.title}
                            <span className={`arrow ${activeSection === index ? 'up' : 'down'}`}>
                                &#9650;
                            </span>
                        </h2>
                        {activeSection === index && <p>{section.content}</p>}
                    </div>
                ))}
            </div>

            <div className="contact-form">
                <h2>Questions about our Terms of Service?</h2>
                <p>Contact our legal team:</p>
                <Link to="/contact" className="contact-button">
                    Contact Us
                </Link>
            </div>
        </div>
    );
};

export default TermsOfService;