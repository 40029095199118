import React, { useState, useEffect } from 'react';
import { Table, TableBody, TableCell, TableContainer, TableHead, TableRow, Paper, Button, Typography, CircularProgress } from '@mui/material';
import '../styles/PerformanceReports.css';

const PerformanceReports = () => {
    const [performanceData, setPerformanceData] = useState([]);
    const [loading, setLoading] = useState(false);
    const [error, setError] = useState(null);

    useEffect(() => {
        fetchPerformanceData();
    }, []);

    const fetchPerformanceData = async () => {
        setLoading(true);
        try {
            const response = await fetch(`https://pilotserver.vercel.app/api/v1/hr/performance-report`, {
                headers: { 'Authorization': `Bearer ${localStorage.getItem('token')}` }
            });
            if (!response.ok) {
                throw new Error('Failed to fetch performance data');
            }
            const data = await response.json();
            setPerformanceData(data);
        } catch (error) {
            setError(error.message);
        } finally {
            setLoading(false);
        }
    };

    return (
        <Paper className="performance-reports-container">
            <Button
                variant="contained"
                onClick={fetchPerformanceData}
                disabled={loading}
                className="generate-report-button"
            >
                {loading ? <CircularProgress size={24} /> : 'Generate Report'}
            </Button>
            {error && (
                <Typography variant="body1" color="error" className="error-message">
                    Error fetching data: {error}
                </Typography>
            )}
            <TableContainer className="table-container">
                <Table className="data-table">
                    <TableHead>
                        <TableRow>
                            <TableCell>Employee Name</TableCell>
                            <TableCell>Completed Tasks</TableCell>
                            <TableCell>Total Tasks</TableCell>
                            <TableCell>Completion Rate</TableCell>
                        </TableRow>
                    </TableHead>
                    <TableBody>
                        {performanceData.map((employee) => (
                            <TableRow key={employee.employeeId}>
                                <TableCell>{employee.name}</TableCell>
                                <TableCell>{employee.completedTasks}</TableCell>
                                <TableCell>{employee.totalTasks}</TableCell>
                                <TableCell>{`${employee.completionRate.toFixed(2)}%`}</TableCell>
                            </TableRow>
                        ))}
                    </TableBody>
                </Table>
            </TableContainer>
        </Paper>
    );
};

export default PerformanceReports;
