import React, { useState, useEffect, useRef } from "react";
import { Link } from "react-router-dom";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faBars, faTimes, faUser } from "@fortawesome/free-solid-svg-icons"; // Import FontAwesome icons
import logo from '../assets/logo.png';
import '../styles/Navbar.css';

const Navbar = () => {
    const [sidebar, setSidebar] = useState(false);
    const sidebarRef = useRef(null);

    const showSidebar = () => setSidebar(!sidebar);

    const handleClickOutside = (event) => {
        if (sidebarRef.current && !sidebarRef.current.contains(event.target)) {
            setSidebar(false);
        }
    };

    useEffect(() => {
        if (sidebar) {
            document.addEventListener("mousedown", handleClickOutside);
        } else {
            document.removeEventListener("mousedown", handleClickOutside);
        }

        return () => {
            document.removeEventListener("mousedown", handleClickOutside);
        };
    }, [sidebar]);

    return (
        <>
            {/* Main Navbar */}
            <div className="navbar">
                <div className="left">
                    <img src={logo} alt="Company Logo" className="logo-img" />
                </div>
                <div className="center">
                    <a className="navbar-brand" href="/">Pilomation</a>
                    <p className="company-tagline">Pilots Of Automation</p>
                </div>
                <div className="right">
                    {/* Link to login page */}
                    <Link to="/login" className="admin-link">
                        <FontAwesomeIcon icon={faUser} />
                    </Link>
                    {/* Toggle sidebar */}
                    <Link to="#" className="menu-bars" onClick={showSidebar}>
                        <FontAwesomeIcon icon={faBars} />
                    </Link>
                </div>
            </div>

            {/* Sidebar Menu */}
            <nav ref={sidebarRef} className={sidebar ? "nav-menu active" : "nav-menu"}>
                <ul className="nav-menu-items" onClick={showSidebar}>
                    <li className="navbar-toggle">
                        <Link to="#" className="menu-bars">
                            <FontAwesomeIcon icon={faTimes} />
                        </Link>
                    </li>
                    {/* Navigation links */}
                    <li className="nav-item">
                        <Link to="/" className="nav-link">Home</Link>
                    </li>
                    <li className="nav-item">
                        <Link to="/about" className="nav-link">About</Link>
                    </li>
                    <li className="nav-item">
                        <Link to="/services" className="nav-link">Services</Link>
                    </li>
                    <li className="nav-item">
                        <Link to="/portfolio" className="nav-link">Portfolio</Link>
                    </li>
                    <li className="nav-item">
                        <Link to="/contact" className="nav-link">Contact</Link>
                    </li>
                    <li className="nav-item">
                        <Link to="/careers" className="nav-link">Careers</Link>
                    </li>
                    <li className="nav-item">
                        <Link to="/blogs" className="nav-link">Blogs</Link>
                    </li>
                </ul>
            </nav>
        </>
    );
}

export default Navbar;
