import React, { useState, useEffect } from 'react';
import { 
    Typography, Grid, TextField, Button, Box, Paper, CircularProgress, Alert 
} from '@mui/material';
import { DragDropContext, Droppable, Draggable } from 'react-beautiful-dnd';
import styled from 'styled-components';

const KanbanBoard = () => {
    const [boards, setBoards] = useState([]);
    const [loading, setLoading] = useState(true);
    const [error, setError] = useState(null);
    const [newBoard, setNewBoard] = useState({ title: '' });
    const [newTasks, setNewTasks] = useState({});

    useEffect(() => {
        fetchKanbanBoards();
    }, []);

    const fetchKanbanBoards = async () => {
        try {
            const response = await fetch(`https://pilotserver.vercel.app/api/v1/hr/kanban`, {
                headers: {
                    'Authorization': `Bearer ${localStorage.getItem('token')}`,
                }
            });
            if (!response.ok) throw new Error('Failed to fetch Kanban boards');
            const data = await response.json();
            setBoards(data);
        } catch (err) {
            setError(err.message);
        } finally {
            setLoading(false);
        }
    };

    const handleDragEnd = async (result) => {
        if (!result.destination) return;

        const { source, destination } = result;
        const updatedBoards = [...boards];
        const [updatedBoard] = updatedBoards.filter(board => board._id === result.draggableId.split('-')[0]);
        const sourceColumn = updatedBoard.columns[source.droppableId];
        const destColumn = updatedBoard.columns[destination.droppableId];
        const [removed] = sourceColumn.tasks.splice(source.index, 1);
        destColumn.tasks.splice(destination.index, 0, removed);

        setBoards(updatedBoards);

        try {
            await fetch(`https://pilotserver.vercel.app/api/v1/hr/kanban/${updatedBoard._id}`, {
                method: 'PUT',
                headers: {
                    'Content-Type': 'application/json',
                    'Authorization': `Bearer ${localStorage.getItem('token')}`,
                },
                body: JSON.stringify(updatedBoard),
            });
        } catch (err) {
            console.error('Failed to update Kanban board:', err);
        }
    };

    const handleNewBoardChange = (event) => {
        const { name, value } = event.target;
        setNewBoard({ ...newBoard, [name]: value });
    };

    const handleNewBoardSubmit = async (event) => {
        event.preventDefault();
        setLoading(true);
        setError(null);
        try {
            const response = await fetch(`https://pilotserver.vercel.app/api/v1/hr/kanban`, {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                    'Authorization': `Bearer ${localStorage.getItem('token')}`,
                },
                body: JSON.stringify({
                    title: newBoard.title,
                    columns: [
                        { name: 'To Do', tasks: [] },
                        { name: 'In Progress', tasks: [] },
                        { name: 'Done', tasks: [] }
                    ]
                }),
            });
            const data = await response.json();
            if (!response.ok) {
                console.error('Server response:', data);
                throw new Error(data.error || 'Failed to create Kanban board');
            }
            setNewBoard({ title: '' });
            fetchKanbanBoards();
        } catch (err) {
            setError(err.message);
            console.error('Error creating Kanban board:', err);
        } finally {
            setLoading(false);
        }
    };

    const handleAddTask = async (boardId, columnIndex) => {
        const taskContent = newTasks[`${boardId}-${columnIndex}`] || '';
        // Check if the input is not empty and is either a non-empty string or a single character
        if (taskContent.trim().length === 0 || (taskContent.length > 1 && taskContent.trim().length === 0)) {
            return; // Don't add empty tasks or tasks with only whitespace
        }

        try {
            const response = await fetch(`https://pilotserver.vercel.app/api/v1/hr/kanban/task`, {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                    'Authorization': `Bearer ${localStorage.getItem('token')}`,
                },
                body: JSON.stringify({
                    boardId,
                    columnIndex,
                    taskContent
                }),
            });
            if (!response.ok) throw new Error('Failed to add task');
            const updatedBoard = await response.json();
            setBoards(boards.map(board => board._id === updatedBoard._id ? updatedBoard : board));
            setNewTasks(prev => ({...prev, [`${boardId}-${columnIndex}`]: ''}));
        } catch (err) {
            console.error('Error adding task:', err);
            setError('Failed to add task');
        }
    };

    return (
        <Box sx={{ padding: 2 }}>
            <Box sx={{ marginBottom: 2 }}>
                <Typography variant="h5" gutterBottom>Create New Kanban Board</Typography>
                <form onSubmit={handleNewBoardSubmit}>
                    <TextField 
                        label="Board Title" 
                        name="title" 
                        value={newBoard.title} 
                        onChange={handleNewBoardChange} 
                        required 
                        fullWidth 
                        sx={{ marginBottom: 2 }}
                    />
                    <Button type="submit" variant="contained" color="primary">
                        Create Board
                    </Button>
                </form>
            </Box>

            {loading && <CircularProgress />}
            {error && <Alert severity="error">{error}</Alert>}

            {!loading && !error && (
                boards.length > 0 ? (
                    boards.map((board) => (
                        <StyledPaper key={board._id}>
                            <Typography variant="h5" gutterBottom>{board.title}</Typography>
                            <DragDropContext onDragEnd={handleDragEnd}>
                                <Grid container spacing={2}>
                                    {board.columns.map((column, columnIndex) => (
                                        <Grid item xs={12} sm={6} md={4} key={columnIndex}>
                                            <ColumnPaper>
                                                <Typography variant="h6" gutterBottom>{column.name}</Typography>
                                                <Droppable droppableId={columnIndex.toString()}>
                                                    {(provided) => (
                                                        <DroppableContainer {...provided.droppableProps} ref={provided.innerRef}>
                                                            {column.tasks.map((task, index) => (
                                                                <Draggable key={`${board._id}-${task._id}`} draggableId={`${board._id}-${task._id}`} index={index}>
                                                                    {(provided, snapshot) => (
                                                                        <TaskPaper
                                                                            ref={provided.innerRef}
                                                                            {...provided.draggableProps}
                                                                            {...provided.dragHandleProps}
                                                                            isDragging={snapshot.isDragging}
                                                                        >
                                                                            <Typography>{task.content}</Typography>
                                                                        </TaskPaper>
                                                                    )}
                                                                </Draggable>
                                                            ))}
                                                            {provided.placeholder}
                                                        </DroppableContainer>
                                                    )}
                                                </Droppable>
                                                <Box sx={{ mt: 2 }}>
                                                    <TextField
                                                        size="small"
                                                        value={newTasks[`${board._id}-${columnIndex}`] || ''}
                                                        onChange={(e) => setNewTasks(prev => ({
                                                            ...prev, 
                                                            [`${board._id}-${columnIndex}`]: e.target.value
                                                        }))}
                                                        placeholder="New task"
                                                        inputProps={{ maxLength: 100 }}
                                                    />
                                                    <Button 
                                                        onClick={() => handleAddTask(board._id, columnIndex)}
                                                        variant="contained" 
                                                        size="small" 
                                                        sx={{ ml: 1 }}
                                                        disabled={!newTasks[`${board._id}-${columnIndex}`]?.trim()}
                                                    >
                                                        Add
                                                    </Button>
                                                </Box>
                                            </ColumnPaper>
                                        </Grid>
                                    ))}
                                </Grid>
                            </DragDropContext>
                        </StyledPaper>
                    ))
                ) : (
                    <Alert severity="info">No Kanban boards yet. Create your first board above!</Alert>
                )
            )}
        </Box>
    );
};

export default KanbanBoard;

const StyledPaper = styled(Paper)`
    padding: 2rem;
    margin-top: 2rem;
    background-color: #f4f6f8;
    border-radius: 12px;
    box-shadow: 0 4px 12px rgba(0, 0, 0, 0.1);
`;

const ColumnPaper = styled(Paper)`
    padding: 1.5rem;
    background-color: #ffffff;
    border-radius: 8px;
    box-shadow: 0 4px 12px rgba(0, 0, 0, 0.05);
`;

const DroppableContainer = styled.div`
    padding: 1rem;
    background-color: #f9f9f9;
    border-radius: 8px;
    min-height: 400px;
`;

const TaskPaper = styled.div`
    padding: 1rem;
    margin-bottom: 1rem;
    background-color: #ffffff;
    border-radius: 8px;
    box-shadow: 0 2px 8px rgba(0, 0, 0, 0.1);
    transition: transform 0.2s ease;
    transform: ${(props) => (props.isDragging ? 'rotate(5deg) scale(1.05)' : 'rotate(0) scale(1)')};
    &:hover {
        box-shadow: 0 4px 16px rgba(0, 0, 0, 0.2);
    }
`;