import React, { useState } from 'react';
import { Link, useNavigate } from 'react-router-dom';
import '../styles/Login.css';

const LoginPage = () => {
    const [email, setEmail] = useState('');
    const [password, setPassword] = useState('');
    const [showPassword, setShowPassword] = useState(false);
    const [error, setError] = useState('');
    const [isLoading, setIsLoading] = useState(false);
    const navigate = useNavigate();

    const handleSubmit = async (e) => {
        e.preventDefault();
        setError('');
        setIsLoading(true);

        try {
            const response = await fetch('https://pilotserver.vercel.app/api/v1/auth/login', {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                },
                body: JSON.stringify({ email, password }),
                credentials: 'include',
            });

            const data = await response.json();

            if (response.ok) {
                console.log('Login successful:', data);

                // Store token in localStorage
                await localStorage.setItem('token', data.token);

                // Set user role and navigate
                if (data.isAdmin) {
                    await localStorage.setItem('userRole', 'admin');
                    console.log('Admin user detected, navigating to Admin dashboard');
                    navigate('/admin-dashboard');
                } else if (data.isHR) {
                    await localStorage.setItem('userRole', 'hr');
                    console.log('HR user detected, navigating to HR dashboard');
                    navigate('/hr-dashboard');
                } else {
                    await localStorage.setItem('userRole', 'user');
                    console.log('Regular user detected, navigating to User dashboard');
                    navigate('/user-dashboard');
                }
            } else {
                setError(data.errors ? data.errors[0] : 'Login failed. Please try again.');
            }
        } catch (error) {
            console.error('Login error:', error);
            setError('An unexpected error occurred. Please try again later.');
        } finally {
            setIsLoading(false);
        }
    };

    return (
        <div className="login-container">
            <div className="login-form">
                <img src={require('../assets/favicon.png')} alt="Company Logo" className="logo" />
                <h2>Sign in to Pilomation</h2>
                {error && <div className="error-message">{error}</div>}
                <form onSubmit={handleSubmit}>
                    <div className="input-group">
                        <label htmlFor="email">Email</label>
                        <input
                            type="email"
                            id="email"
                            value={email}
                            onChange={(e) => setEmail(e.target.value)}
                            required
                        />
                    </div>
                    <div className="input-group">
                        <label htmlFor="password">Password</label>
                        <div className="password-input">
                            <input
                                type={showPassword ? "text" : "password"}
                                id="password"
                                value={password}
                                onChange={(e) => setPassword(e.target.value)}
                                required
                            />
                            <button
                                type="button"
                                onClick={() => setShowPassword(!showPassword)}
                                className="show-password"
                            >
                                {showPassword ? "Hide" : "Show"}
                            </button>
                        </div>
                    </div>
                    <div className="checkbox-group">
                        <input type="checkbox" id="remember" />
                        <label htmlFor="remember">Remember me</label>
                    </div>
                    <button type="submit" className="login-button" disabled={isLoading}>
                        {isLoading ? 'Signing In...' : 'Sign In'}
                    </button>
                </form>
                <div className="additional-options">
                    <Link to="/Forgot-pass">Forgot password?</Link>
                    <Link to="/signup">Create an account</Link>
                </div>
                <div className="sso-options">
                    {/* Add SSO options here if applicable */}
                </div>
            </div>
        </div>
    );
};

export default LoginPage;