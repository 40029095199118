import React, { useState, useEffect } from 'react';
import { FaProjectDiagram, FaSearch, FaPlus, FaFilter } from 'react-icons/fa';
import '../styles/ProjectsPage.css';

const ProjectsPage = () => {
    const [projects, setProjects] = useState([]);
    const [searchTerm, setSearchTerm] = useState('');
    const [loading, setLoading] = useState(true);

    useEffect(() => {
        fetchProjects();
    }, []);

    const fetchProjects = async () => {
        // Simulate API call
        setTimeout(() => {
            setProjects([
                { id: 1, name: 'AI Integration', status: 'In Progress', department: 'Engineering', completion: 60 },
                { id: 2, name: 'Marketing Campaign', status: 'Planning', department: 'Marketing', completion: 20 },
                { id: 3, name: 'New HR Portal', status: 'Completed', department: 'Human Resources', completion: 100 },
                // Add more projects...
            ]);
            setLoading(false);
        }, 1000);
    };

    const filteredProjects = projects.filter(project =>
        project.name.toLowerCase().includes(searchTerm.toLowerCase()) ||
        project.status.toLowerCase().includes(searchTerm.toLowerCase()) ||
        project.department.toLowerCase().includes(searchTerm.toLowerCase())
    );

    return (
        <div className="page-container projects-page">
            <header className="page-header">
                <h1><FaProjectDiagram /> Projects</h1>
                <div className="header-actions">
                    <button className="btn btn-primary"><FaPlus /> New Project</button>
                </div>
            </header>

            <div className="projects-controls">
                <div className="search-bar">
                    <FaSearch />
                    <input 
                        type="text" 
                        placeholder="Search projects..." 
                        value={searchTerm}
                        onChange={(e) => setSearchTerm(e.target.value)}
                    />
                </div>
                <button className="btn btn-secondary"><FaFilter /> Filter</button>
            </div>

            <div className="projects-list">
                {loading ? (
                    <div className="loading">Loading projects...</div>
                ) : filteredProjects.length > 0 ? (
                    <table className="projects-table">
                        <thead>
                            <tr>
                                <th>Project Name</th>
                                <th>Status</th>
                                <th>Department</th>
                                <th>Completion</th>
                                <th>Actions</th>
                            </tr>
                        </thead>
                        <tbody>
                            {filteredProjects.map(project => (
                                <tr key={project.id}>
                                    <td>{project.name}</td>
                                    <td><span className={`status-badge ${project.status.toLowerCase()}`}>{project.status}</span></td>
                                    <td>{project.department}</td>
                                    <td>
                                        <div className="progress-bar">
                                            <div className="progress" style={{width: `${project.completion}%`}}></div>
                                        </div>
                                        {project.completion}%
                                    </td>
                                    <td>
                                        <button className="btn btn-small">View</button>
                                        <button className="btn btn-small">Edit</button>
                                    </td>
                                </tr>
                            ))}
                        </tbody>
                    </table>
                ) : (
                    <p>No projects found matching your search.</p>
                )}
            </div>

            <div className="project-stats">
                <h2>Project Overview</h2>
                <div className="stats-grid">
                    <div className="stat-card">
                        <h3>Total Projects</h3>
                        <p className="stat-value">{projects.length}</p>
                    </div>
                    <div className="stat-card">
                        <h3>In Progress</h3>
                        <p className="stat-value">{projects.filter(p => p.status === 'In Progress').length}</p>
                    </div>
                    <div className="stat-card">
                        <h3>Completed</h3>
                        <p className="stat-value">{projects.filter(p => p.status === 'Completed').length}</p>
                    </div>
                    <div className="stat-card">
                        <h3>Average Completion</h3>
                        <p className="stat-value">
                            {Math.round(projects.reduce((sum, p) => sum + p.completion, 0) / projects.length)}%
                        </p>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default ProjectsPage;