import React from 'react';
import { useParams } from 'react-router-dom';
import styled from 'styled-components';

const contentMap = {
    'platform-overview': {
        title: 'Platform Overview',
        content: [
            {
                subtitle: 'Revolutionizing Data Management',
                text: 'Our platform offers a comprehensive suite of tools designed to streamline your data management processes. From data ingestion to analysis and visualization, we provide end-to-end solutions that empower your organization to make data-driven decisions.'
            },
            {
                subtitle: 'Scalable and Secure',
                text: 'Built with enterprise-grade security and scalability in mind, our platform grows with your business. Whether you\'re a startup or a Fortune 500 company, we have the infrastructure to support your data needs.'
            }
        ]
    },
    'ai-ml': {
        title: 'AI / ML',
        content: [
            {
                subtitle: 'Cutting-Edge AI Solutions',
                text: 'Harness the power of artificial intelligence with our state-of-the-art AI models. From natural language processing to computer vision, our AI capabilities can be seamlessly integrated into your existing workflows.'
            },
            {
                subtitle: 'Machine Learning at Scale',
                text: 'Our platform provides the tools and infrastructure needed to build, train, and deploy machine learning models at scale. Accelerate your ML initiatives with our optimized compute resources and streamlined deployment processes.'
            }
        ]
    },
    'collaboration': {
        title: 'Collaboration',
        content: [
            {
                subtitle: 'Seamless Team Cooperation',
                text: 'Foster collaboration across your organization with our intuitive team-based tools. Share insights, collaborate on projects, and maintain version control effortlessly.'
            },
            {
                subtitle: 'Real-Time Communication',
                text: 'Stay connected with your team through integrated chat, video conferencing, and collaborative document editing features.'
            }
        ]
    },
    'governance': {
        title: 'Governance',
        content: [
            {
                subtitle: 'Data Compliance Made Easy',
                text: 'Ensure compliance with data regulations such as GDPR, CCPA, and HIPAA. Our governance tools provide audit trails, access controls, and data lineage tracking to keep your data practices in check.'
            },
            {
                subtitle: 'Policy Management',
                text: 'Implement and enforce data policies across your organization with our centralized policy management system.'
            }
        ]
    },
    'all-features': {
        title: 'All Features',
        content: [
            {
                subtitle: 'Comprehensive Toolkit',
                text: 'Explore our full suite of features designed to cover every aspect of your data management needs. From data ingestion to advanced analytics, we\'ve got you covered.'
            },
            {
                subtitle: 'Continuous Innovation',
                text: 'We\'re constantly adding new features and improvements based on customer feedback and industry trends. Stay ahead of the curve with our evolving platform.'
            }
        ]
    },
    'by-industry': {
        title: 'By Industry',
        content: [
            {
                subtitle: 'Tailored Solutions',
                text: 'Discover how our platform adapts to the unique needs of various industries, including finance, healthcare, retail, and more.'
            },
            {
                subtitle: 'Industry-Specific Integrations',
                text: 'Take advantage of our industry-specific integrations and templates to get up and running quickly with solutions tailored to your sector.'
            }
        ]
    },
    'by-role': {
        title: 'By Role',
        content: [
            {
                subtitle: 'Role-Based Experiences',
                text: 'Whether you\'re a data scientist, business analyst, or C-level executive, our platform offers tailored experiences to match your specific needs and responsibilities.'
            },
            {
                subtitle: 'Customizable Dashboards',
                text: 'Create personalized dashboards that highlight the metrics and insights most relevant to your role.'
            }
        ]
    },
    'integrations': {
        title: 'Integrations',
        content: [
            {
                subtitle: 'Seamless Connectivity',
                text: 'Connect with your favorite tools and services through our extensive library of pre-built integrations. From CRMs to marketing automation platforms, we ensure your data flows smoothly across your entire tech stack.'
            },
            {
                subtitle: 'API and Custom Integrations',
                text: 'For unique needs, leverage our robust API to build custom integrations or work with our professional services team to develop tailor-made solutions.'
            }
        ]
    },
    'case-studies': {
        title: 'Case Studies',
        content: [
            {
                subtitle: 'Success Stories',
                text: 'Explore real-world examples of how organizations across various industries have leveraged our platform to drive innovation, increase efficiency, and achieve tangible business results.'
            },
            {
                subtitle: 'Measurable Impact',
                text: 'Dive into the numbers and see the quantifiable impact our solutions have had on our clients\' operations, from cost savings to revenue growth.'
            }
        ]
    },
    'blog': {
        title: 'Blog',
        content: [
            {
                subtitle: 'Industry Insights',
                text: 'Stay informed with our regularly updated blog, featuring articles on data trends, best practices, and thought leadership pieces from industry experts.'
            },
            {
                subtitle: 'Product Updates',
                text: 'Get the latest news on product releases, feature updates, and tips for getting the most out of our platform.'
            }
        ]
    },
    'documentation': {
        title: 'Documentation',
        content: [
            {
                subtitle: 'Comprehensive Guides',
                text: 'Access our extensive documentation library, complete with step-by-step guides, API references, and best practice recommendations to help you make the most of our platform.'
            },
            {
                subtitle: 'Interactive Tutorials',
                text: 'Learn at your own pace with our interactive tutorials, designed to get you up to speed quickly on various aspects of the platform.'
            }
        ]
    },
    'ebooks': {
        title: 'eBooks',
        content: [
            {
                subtitle: 'In-Depth Resources',
                text: 'Download our collection of eBooks covering a wide range of topics, from data strategy to advanced analytics techniques. These comprehensive guides provide valuable insights for data professionals at all levels.'
            },
            {
                subtitle: 'Expert Authored',
                text: 'Our eBooks are authored by industry experts and thought leaders, ensuring you get access to cutting-edge knowledge and proven strategies.'
            }
        ]
    },
    'webinars': {
        title: 'Webinars',
        content: [
            {
                subtitle: 'Live Learning',
                text: 'Join our regular webinars to learn from industry experts, see product demonstrations, and get your questions answered in real-time.'
            },
            {
                subtitle: 'On-Demand Access',
                text: 'Can\'t make it to a live session? Access our library of recorded webinars at your convenience, covering a wide range of topics and use cases.'
            }
        ]
    },
    'community': {
        title: 'Community',
        content: [
            {
                subtitle: 'Connect and Collaborate',
                text: 'Join our vibrant community of data professionals. Share ideas, ask questions, and collaborate on projects with peers from around the world.'
            },
            {
                subtitle: 'User Groups',
                text: 'Participate in local and virtual user groups to network with other professionals in your area and industry.'
            }
        ]
    },
    'press': {
        title: 'Press',
        content: [
            {
                subtitle: 'Latest News',
                text: 'Stay up to date with our latest press releases, company announcements, and media coverage. Discover how we\'re making waves in the data industry.'
            },
            {
                subtitle: 'Media Resources',
                text: 'Access our media kit, including company logos, executive bios, and product images for press and partnership use.'
            }
        ]
    }
};

const Container = styled.div`
    max-width: 800px;
    margin: 0 auto;
    padding: 2rem;
    font-family: Arial, sans-serif;
`;

const Title = styled.h1`
    color: #333;
    border-bottom: 2px solid #0066cc;
    padding-bottom: 0.5rem;
`;

const Section = styled.section`
    margin-bottom: 2rem;
`;

const Subtitle = styled.h2`
    color: #0066cc;
    font-size: 1.2rem;
`;

const Paragraph = styled.p`
    line-height: 1.6;
    color: #444;
`;

const ErrorContainer = styled.div`
    text-align: center;
    padding: 2rem;
    background-color: #f8d7da;
    border: 1px solid #f5c6cb;
    border-radius: 4px;
    color: #721c24;
`;

const FooterDisplayPage = () => {
    const { itemId } = useParams();
    const content = contentMap[itemId];

    if (!content) {
        return (
            <ErrorContainer>
                <h1>404: Page Not Found</h1>
                <p>We're sorry, but the page you're looking for doesn't exist.</p>
            </ErrorContainer>
        );
    }

    return (
        <Container>
            <Title>{content.title}</Title>
            {content.content.map((section, index) => (
                <Section key={index}>
                    <Subtitle>{section.subtitle}</Subtitle>
                    <Paragraph>{section.text}</Paragraph>
                </Section>
            ))}
        </Container>
    );
};

export default FooterDisplayPage;
