import React, { useState, useEffect } from 'react';
import { List, ListItem, ListItemText, TextField, Button, Paper } from '@mui/material';
import styled from 'styled-components';

// Ensure API_BASE_URL is defined
// const API_BASE_URL = 'https://pilotserver.vercel.app'; // or import it from your config

const Messaging = () => {
    const [messages, setMessages] = useState([]);
    const [newMessage, setNewMessage] = useState({ recipientId: '', content: '' });

    useEffect(() => {
        fetchMessages();
    }, []);

    const fetchMessages = async () => {
        try {
            const response = await fetch(`$https://pilotserver.vercel.app/api/v1/hr/messages`, {
                headers: { 'Authorization': `Bearer ${localStorage.getItem('token')}` }
            });
            if (!response.ok) throw new Error('Failed to fetch messages');
            const data = await response.json();
            setMessages(data);
        } catch (error) {
            console.error('Error fetching messages:', error);
            alert('Error fetching messages');
        }
    };

    const sendMessage = async () => {
        try {
            const response = await fetch(`https://pilotserver.vercel.app/api/v1/hr/messages`, {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                    'Authorization': `Bearer ${localStorage.getItem('token')}`
                },
                body: JSON.stringify(newMessage)
            });
            if (!response.ok) throw new Error('Failed to send message');
            setNewMessage({ recipientId: '', content: '' });
            fetchMessages();
        } catch (error) {
            console.error('Error sending message:', error);
            alert('Error sending message');
        }
    };

    return (
        <StyledPaper>
            <StyledList>
                {messages.map((message) => (
                    <StyledListItem key={message._id}>
                        <ListItemText primary={message.content} secondary={`From: ${message.sender}`} />
                    </StyledListItem>
                ))}
            </StyledList>
            <StyledTextField 
                label="Recipient ID" 
                fullWidth 
                margin="normal"
                value={newMessage.recipientId} 
                onChange={(e) => setNewMessage({...newMessage, recipientId: e.target.value})} 
            />
            <StyledTextField 
                label="Message" 
                fullWidth 
                margin="normal" 
                multiline 
                rows={4}
                value={newMessage.content} 
                onChange={(e) => setNewMessage({...newMessage, content: e.target.value})} 
            />
            <StyledButton variant="contained" onClick={sendMessage}>Send Message</StyledButton>
        </StyledPaper>
    );
};

export default Messaging;

const StyledPaper = styled(Paper)`
    padding: 2rem;
    margin: 2rem auto;
    max-width: 800px;
    background: #f9f9f9;
    border-radius: 12px;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
`;

const StyledList = styled(List)`
    max-height: 300px;
    overflow-y: auto;
    margin-bottom: 1.5rem;
    padding-right: 1rem;
`;

const StyledListItem = styled(ListItem)`
    border-bottom: 1px solid #e0e0e0;
`;

const StyledTextField = styled(TextField)`
    margin-bottom: 1.5rem !important;
    & .MuiInputBase-root {
        background: #ffffff;
        border-radius: 8px;
    }
    & .MuiOutlinedInput-notchedOutline {
        border-color: #e0e0e0;
    }
`;

const StyledButton = styled(Button)`
    background-color: #1976d2 !important;
    color: #fff !important;
    padding: 0.5rem 1.5rem;
    border-radius: 8px;
    &:hover {
        background-color: #1565c0 !important;
    }
`;
