import React from 'react';
import { Link } from 'react-router-dom';
import Slider from 'react-slick';
import 'slick-carousel/slick/slick.css';
import 'slick-carousel/slick/slick-theme.css';
import banner1 from '../assets/banner1.gif';  
import banner2 from '../assets/banner2.gif';
import banner3 from '../assets/banner3.gif';
import banner4 from '../assets/banner4.gif';
import banner5 from '../assets/banner5.gif';
import banner6 from '../assets/banner6.gif';
import banner7 from '../assets/banner7.gif';
import '../styles/Home.css';

const Banners = () => {
    const settings = {
        dots: true,
        infinite: true,
        speed: 4000, // Increase the speed value to make it slower
        slidesToShow: 1,
        slidesToScroll: 1,
        autoplay: true,
        autoplaySpeed: 5000, // Increase the autoplaySpeed value to make it slower
        nextArrow: <NextArrow />,
        prevArrow: <PrevArrow />,
    };

    return (
        <section className="banner-section">
            <div className="banner-content">
                <h1 className="banner-title">Revolutionize Your Business with Pilomation</h1>
                <p className="banner-subtitle">Cutting-edge software solutions for modern AI generative Solutions</p>
                <div className="banner-features">
                    <div className="feature">
                        <i className="fas fa-rocket"></i>
                        <span>Boost Productivity</span>
                    </div>
                    <div className="feature">
                        <i className="fas fa-shield-alt"></i>
                        <span>Enhanced Security</span>
                    </div>
                    <div className="feature">
                        <i className="fas fa-chart-line"></i>
                        <span>Data-Driven Insights</span>
                    </div>
                    <div className="feature">
                        <i className="fas fa-cloud"></i>
                        <span>Cloud Integration</span>
                    </div>
                    <div className="feature">
                        <i className="fas fa-user-friends"></i>
                        <span>x24/7 Support System</span>
                    </div>
                </div>
                <div className="banner-cta">
                    {/* <Link to="/demo" className="cta-button primary">Request a Demo</Link> */}
                    <Link to="/services" className="cta-button secondary">Explore Services</Link>
                    <Link to="/contact" className="cta-button tertiary">Contact Us</Link>
                </div>
            </div>
            <div className="banner-slider">
                <Slider {...settings}>
                    <div>
                        <Link to="/services"><img src={banner1} alt="Services" className="banner-img" /></Link>
                    </div>
                    <div>
                        <Link to="/careers"><img src={banner2} alt="Careers" className="banner-img" /></Link>
                    </div>
                    <div>
                        <Link to="/portfolio"><img src={banner3} alt="Portfolio" className="banner-img" /></Link>
                    </div>
                    <div>
                        <Link to="/portfolio"><img src={banner4} alt="Portfolio" className="banner-img" /></Link>
                    </div>
                    <div>
                        <Link to="/portfolio"><img src={banner5} alt="Portfolio" className="banner-img" /></Link>
                    </div>
                    <div>
                        <Link to="/portfolio"><img src={banner6} alt="Portfolio" className="banner-img" /></Link>
                    </div>
                    <div>
                        <Link to="/portfolio"><img src={banner7} alt="Portfolio" className="banner-img" /></Link>
                    </div>
                    
                </Slider>
            </div>
        </section>
    );
}

const NextArrow = (props) => {
    const { className, style, onClick } = props;
    return (
        <div
            className={`${className} custom-arrow next-arrow`}
            style={{ ...style }}
            onClick={onClick}
        >
            &gt;
        </div>
    );
}

const PrevArrow = (props) => {
    const { className, style, onClick } = props;
    return (
        <div
            className={`${className} custom-arrow prev-arrow`}
            style={{ ...style }}
            onClick={onClick}
        >
            &lt;
        </div>
    );
}

export default Banners;
