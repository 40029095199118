import React, { useState, useEffect } from 'react';
import { 
    Table, TableBody, TableCell, TableContainer, TableHead, TableRow, 
    Paper, Button, TextField, Dialog, DialogTitle, DialogContent, DialogActions, 
    Skeleton, Alert 
} from '@mui/material';

const EmployeeManagement = () => {
    const [employees, setEmployees] = useState([]);
    const [openDialog, setOpenDialog] = useState(false);
    const [newEmployee, setNewEmployee] = useState({ firstName: '', lastName: '', email: '', phone: '', password: '' });
    const [loading, setLoading] = useState(false);
    const [error, setError] = useState(null);

    useEffect(() => {
        fetchEmployees();
    }, []);

    const fetchEmployees = async () => {
        setLoading(true);
        setError(null);
        try {
            const response = await fetch(`https://pilotserver.vercel.app/api/v1/hr/employees`, {
                headers: { 'Authorization': `Bearer ${localStorage.getItem('token')}` }
            });
            if (!response.ok) throw new Error('Failed to fetch employees');
            const data = await response.json();
            setEmployees(data);
        } catch (error) {
            setError(error.message);
            console.error('Error fetching employees:', error);
        } finally {
            setLoading(false);
        }
    };

    const handleCreateEmployee = async () => {
        if (!newEmployee.firstName || !newEmployee.lastName || !newEmployee.email || !newEmployee.phone || !newEmployee.password) {
            setError("All fields are required");
            return;
        }

        setLoading(true);
        setError(null);
        try {
            const response = await fetch(`https://pilotserver.vercel.app/api/v1/auth/api/v1/hr/employees`, {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                    'Authorization': `Bearer ${localStorage.getItem('token')}`
                },
                body: JSON.stringify(newEmployee)
            });
            if (!response.ok) throw new Error('Failed to create employee');
            setOpenDialog(false);
            fetchEmployees();
        } catch (error) {
            setError(error.message);
            console.error('Error creating employee:', error);
        } finally {
            setLoading(false);
        }
    };

    return (
        <div>
            <Button variant="contained" onClick={() => setOpenDialog(true)}>Add Employee</Button>
            {error && <Alert severity="error">{error}</Alert>}
            <TableContainer component={Paper}>
                <Table>
                    <TableHead>
                        <TableRow>
                            <TableCell>Name</TableCell>
                            <TableCell>Email</TableCell>
                            <TableCell>Phone</TableCell>
                        </TableRow>
                    </TableHead>
                    <TableBody>
                        {loading ? (
                            Array.from(new Array(5)).map((_, index) => (
                                <TableRow key={index}>
                                    <TableCell><Skeleton variant="text" /></TableCell>
                                    <TableCell><Skeleton variant="text" /></TableCell>
                                    <TableCell><Skeleton variant="text" /></TableCell>
                                </TableRow>
                            ))
                        ) : (
                            employees.map((employee) => (
                                <TableRow key={employee._id}>
                                    <TableCell>{`${employee.firstName} ${employee.lastName}`}</TableCell>
                                    <TableCell>{employee.email}</TableCell>
                                    <TableCell>{employee.phone}</TableCell>
                                </TableRow>
                            ))
                        )}
                    </TableBody>
                </Table>
            </TableContainer>
            <Dialog open={openDialog} onClose={() => setOpenDialog(false)}>
                <DialogTitle>Add New Employee</DialogTitle>
                <DialogContent>
                    <TextField 
                        label="First Name" fullWidth margin="normal" 
                        value={newEmployee.firstName} 
                        onChange={(e) => setNewEmployee({...newEmployee, firstName: e.target.value})} 
                    />
                    <TextField 
                        label="Last Name" fullWidth margin="normal"
                        value={newEmployee.lastName} 
                        onChange={(e) => setNewEmployee({...newEmployee, lastName: e.target.value})} 
                    />
                    <TextField 
                        label="Email" fullWidth margin="normal"
                        value={newEmployee.email} 
                        onChange={(e) => setNewEmployee({...newEmployee, email: e.target.value})} 
                    />
                    <TextField 
                        label="Phone" fullWidth margin="normal"
                        value={newEmployee.phone} 
                        onChange={(e) => setNewEmployee({...newEmployee, phone: e.target.value})} 
                    />
                    <TextField 
                        label="Password" type="password" fullWidth margin="normal"
                        value={newEmployee.password} 
                        onChange={(e) => setNewEmployee({...newEmployee, password: e.target.value})} 
                    />
                </DialogContent>
                <DialogActions>
                    <Button onClick={() => setOpenDialog(false)}>Cancel</Button>
                    <Button onClick={handleCreateEmployee} disabled={loading}>Create</Button>
                </DialogActions>
            </Dialog>
        </div>
    );
};

export default EmployeeManagement;
