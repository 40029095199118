import React from 'react';
import '../styles/Services.css';

const servicesData = [
    {
        image: require('../assets/Ai.png'),
        title: 'AI & Machine Learning',
        description: 'Cutting-edge AI and ML solutions to harness data.',
        link: '/blog/ai-machine-learning'
    },
    {
        image: require('../assets/software-development.jpg'),
        title: 'Software Development',
        description: 'Custom software solutions for business needs.',
        link: '/blog/software-development'
    },
    {
        image: require('../assets/cloud.jpeg'),
        title: 'Cloud Services',
        description: 'Scalable and secure cloud solutions.',
        link: '/blog/cloud-services'
    },
    {
        image: require('../assets/data-analytics.jpg'),
        title: 'Data Analytics',
        description: 'Transform data into actionable insights.',
        link: '/blog/data-analytics'
    },
    {
        image: require('../assets/cybersecurity.jpg'),
        title: 'Cybersecurity',
        description: 'Comprehensive security solutions to protect your business.',
        link: '/blog/cybersecurity'
    },
    {
        image: require('../assets/it-consulting.jpg'),
        title: 'IT Consulting',
        description: 'Expert IT consulting to optimize your infrastructure.',
        link: '/blog/it-consulting'
    }
];

const branchServices = [
    { title: 'AI Consulting', link: '/blog/ai-consulting' },
    { title: 'ML Development', link: '/blog/ml-development' },
    { title: 'Data Engineering', link: '/blog/data-engineering' },
    { title: 'Cloud Migration', link: '/blog/cloud-migration' },
    { title: 'UI/UX Design', link: '/blog/ui-ux-design' },
    { title: 'DevOps & Automation', link: '/blog/devops-automation' },
    { title: 'Custom Software Development', link: '/blog/custom-software-development' },
    { title: 'Mobile App Development', link: '/blog/mobile-app-development' },
    { title: 'Enterprise Solutions', link: '/blog/enterprise-solutions' },
    { title: 'IoT Solutions', link: '/blog/iot-solutions' },
    { title: 'Cybersecurity Services', link: '/blog/cybersecurity-services' },
    { title: 'Quality Assurance & Testing', link: '/blog/qa-testing' },
    { title: 'API Development & Integration', link: '/blog/api-development' },
    { title: 'Blockchain Solutions', link: '/blog/blockchain-solutions' },
    { title: 'IT Consulting', link: '/blog/it-consulting' }
];

const ServiceCard = ({ image, title, description, link }) => (
    <div className="service-card">
        <img src={image} alt={title} className="service-card-image" />
        <h3 className="service-card-title">{title}</h3>
        <p className="service-card-description">{description}</p>
        <a href={link} className="service-card-link">Learn More</a>
    </div>
);

const ServiceBranch = ({ services }) => (
    <div className="service-branch-container">
        <div className="animated-line"></div>
        {services.map((service, index) => (
            <div key={index} className="branch-node">
                <a href={service.link} className="branch-link">
                    {service.title}
                </a>
            </div>
        ))}
    </div>
);

const Services = () => (
    <div className="services-page">
        <h1>Welcome to Our Service Page</h1>
        
        <div className="services-grid">
            {servicesData.map((service, index) => (
                <ServiceCard key={index} {...service} />
            ))}
        </div>
        
        <p>Explore our top services and works in the field of AI and software solutions.</p>
        
        <ServiceBranch services={branchServices} />
        
        <div className="our-values">
            <h2>Our Values</h2>
            <p>Collaboration, innovation, and excellence in every project we undertake.</p>
        </div>
        
        <div className="collaborate-section">
            <div className="benefits">
                <h2>Benefits of Partnering with Pilomations</h2>
                <ul>
                    <li>Access to cutting-edge technology</li>
                    <li>Expertise in AI and software development</li>
                    <li>Innovative and tailored solutions</li>
                    <li>Strong focus on client satisfaction</li>
                </ul>
            </div>
            <div className="collaborate-button-container">
                <a href="/contact" className="collaborate-button">Collaborate with Us</a>
            </div>
        </div>
        
        <footer className="footer">
            <p>&copy; {new Date().getFullYear()} Pilomations. All rights reserved.</p>
        </footer>
    </div>
);

export default Services;
