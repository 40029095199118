import React, { useState } from 'react';
import { Download, RefreshCw } from 'lucide-react';
import { QRCodeCanvas } from 'qrcode.react';


const QRGenerator = () => {
  const [text, setText] = useState('');
  const [qrData, setQrData] = useState('');

  const handleGenerate = () => {
    if (text) {
      setQrData(text);
    }
  };

  const downloadQR = () => {
    if (!qrData) return;

    const canvas = document.querySelector('#qr-code canvas');
    if (!canvas) return;

    const pngUrl = canvas.toDataURL('image/png');
    const downloadLink = document.createElement('a');
    downloadLink.href = pngUrl;
    downloadLink.download = `qr-${text}.png`;
    document.body.appendChild(downloadLink);
    downloadLink.click();
    document.body.removeChild(downloadLink);
  };

  return (
    <div className="w-full max-w-md mx-auto bg-white rounded-lg shadow-lg">
      <div className="p-6">
        <h2 className="text-xl font-bold text-center mb-6">QR Code Generator</h2>
        
        <div className="space-y-4">
          <div className="flex gap-2">
            <input
              type="text"
              value={text}
              onChange={(e) => setText(e.target.value)}
              placeholder="Enter text"
              className="flex-1 p-2 border rounded-lg"
            />
            <button
              onClick={handleGenerate}
              className="bg-blue-600 text-white px-4 py-2 rounded-lg hover:bg-blue-700 flex items-center gap-2"
            >
              <RefreshCw className="w-4 h-4" />
              Generate
            </button>
          </div>

          {qrData && (
            <div className="space-y-4">
              <div className="border rounded-lg p-4 bg-white">
                <div id="qr-code">
                  <QRCodeCanvas value={qrData} size={256} level="H" includeMargin={true} />
                </div>
              </div>

              <button
                onClick={downloadQR}
                className="w-full bg-green-600 text-white px-4 py-2 rounded-lg hover:bg-green-700 flex items-center justify-center gap-2"
              >
                <Download className="w-4 h-4" />
                Download QR Code
              </button>

              <div className="text-sm text-gray-600">
                <p>Generated for: {text}</p>
                <p>Time: {new Date().toLocaleString()}</p>
              </div>
            </div>
          )}
        </div>
      </div>
    </div>
  );
};

export default QRGenerator;