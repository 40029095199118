import React, { useState } from 'react';
import { Link } from 'react-router-dom';
import { FaFacebookF, FaTwitter, FaLinkedinIn, FaYoutube, FaInstagram } from 'react-icons/fa';
import '../styles/ContactUs.css';

const ContactUs = () => {
    const [formData, setFormData] = useState({
        name: '',
        email: '',
        phone: '',
        company: '',
        message: '',
        reason: 'General Inquiry'
    });

    const handleChange = (e) => {
        setFormData({ ...formData, [e.target.name]: e.target.value });
    };

    const handleSubmit = async (e) => {
        e.preventDefault();

        try {
            const response = await fetch('https://pilotserver.vercel.app/api/v1/contactus/contact', {// Updated API versioning
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                },
                body: JSON.stringify(formData),
            });

            const data = await response.json();

            if (response.ok) {
                alert('Your message has been sent successfully!');
                setFormData({
                    name: '',
                    email: '',
                    phone: '',
                    company: '',
                    message: '',
                    reason: 'General Inquiry'
                });
            } else {
                alert(data.message || 'Failed to send your message. Please try again.');
            }
        } catch (error) {
            console.error('Error sending message:', error);
            alert('An unexpected error occurred. Please try again later.');
        }
    };

    return (
        <div className="contact-us-container">
            <h1>Contact Pilomation</h1>
            <p className="intro-text">
                We're excited to hear from you! Whether you're interested in our AI solutions, 
                have a technical question, or want to explore partnership opportunities, 
                we're here to help.
            </p>

            <div className="contact-content">
                <form className="contact-form" onSubmit={handleSubmit}>
                    <h2>Get in Touch</h2>
                    <input 
                        type="text" 
                        name="name" 
                        placeholder="Your Name" 
                        value={formData.name} 
                        onChange={handleChange} 
                        required 
                    />
                    <input 
                        type="email" 
                        name="email" 
                        placeholder="Your Email" 
                        value={formData.email} 
                        onChange={handleChange} 
                        required 
                    />
                    <input
                        type="tel"
                        name="phone"
                        placeholder="Your Phone"
                        value={formData.phone}
                        onChange={handleChange}
                    />
                    <input 
                        type="text" 
                        name="company" 
                        placeholder="Company Name" 
                        value={formData.company} 
                        onChange={handleChange} 
                    />
                    <select 
                        name="reason" 
                        value={formData.reason} 
                        onChange={handleChange}
                    >
                        <option value="General Inquiry">General Inquiry</option>
                        <option value="Sales">Sales</option>
                        <option value="Technical Support">Technical Support</option>
                        <option value="Partnerships">Partnerships</option>
                        <option value="Careers">Careers</option>
                    </select>
                    <textarea 
                        name="message" 
                        placeholder="Your Message" 
                        value={formData.message} 
                        onChange={handleChange} 
                        required
                    ></textarea>
                    <button type="submit">Send Message</button>
                </form>

                <div className="contact-info">
                    <h2>Contact Information</h2>
                    <p><strong>Email:</strong> <a href="mailto:contact@pilomation.com">contact@pilomation.com</a></p>
                    {/* <p><strong>Phone:</strong> +91 </p> */}
                    
                    <div className="social-links">
                        <a href="https://www.facebook.com/profile.php?id=61562671923171" aria-label="Facebook"><FaFacebookF /></a>
                        <a href="https://x.com/pilomation" aria-label="Twitter"><FaTwitter /></a>
                        <a href="https://www.linkedin.com/company/pilomation/" aria-label="LinkedIn"><FaLinkedinIn /></a>
                        <a href="https://www.youtube.com/@Pilomation" aria-label="YouTube"><FaYoutube /></a>
                        <a href="https://www.instagram.com/pilomations/" aria-label="Instagram"><FaInstagram /></a>
                    </div>

                    <div className="cta-buttons">
                        <li><Link to="/terms-of-service" className="cta-button">Terms of Service</Link></li>
                        <li><Link to="/CookiesPolicy" className="cta-button">Cookies & Policy</Link></li>
                    </div>
                </div>
            </div>

            <div className="faq-section">
                <h2>Frequently Asked Questions</h2>
                <div className="faq-item">
                    <h3>What industries does Pilomation serve?</h3>
                    <p>Pilomation's AI solutions are versatile and can be applied across various industries including finance, healthcare, manufacturing, and more.</p>
                </div>
                <div className="faq-item">
                    <h3>How can I request a product demo?</h3>
                    <p>You can request a demo by clicking the "Schedule a Demo" button above or by selecting "Sales" in the contact form.</p>
                </div>
                {/* Add more FAQ items as needed */}
            </div>
        </div>
    );
};

export default ContactUs;
