import React, { useState, useEffect } from 'react';
import { Table, Badge, Button, Modal } from 'react-bootstrap';

const NotificationsPage = () => {
    const [notifications, setNotifications] = useState([]);
    const [showModal, setShowModal] = useState(false);
    const [selectedNotification, setSelectedNotification] = useState(null);

    useEffect(() => {
        fetchNotifications();
    }, []);

    const fetchNotifications = async () => {
        // Simulated API call
        const response = await fetch('/api/notifications');
        const data = await response.json();
        setNotifications(data);
    };

    const handleMarkAsRead = async (id) => {
        // Simulated API call
        await fetch(`/api/notifications/${id}/read`, { method: 'POST' });
        setNotifications(notifications.map(notif =>
            notif.id === id ? { ...notif, read: true } : notif
        ));
    };

    const handleNotificationClick = (notification) => {
        setSelectedNotification(notification);
        setShowModal(true);
    };

    return (
        <div className="notifications-page">
            <h1>Notifications</h1>
            <Table striped bordered hover>
                <thead>
                    <tr>
                        <th>Date</th>
                        <th>Message</th>
                        <th>Status</th>
                        <th>Action</th>
                    </tr>
                </thead>
                <tbody>
                    {notifications.map(notification => (
                        <tr key={notification.id} onClick={() => handleNotificationClick(notification)}>
                            <td>{new Date(notification.date).toLocaleDateString()}</td>
                            <td>{notification.message}</td>
                            <td>
                                <Badge bg={notification.read ? "secondary" : "primary"}>
                                    {notification.read ? "Read" : "Unread"}
                                </Badge>
                            </td>
                            <td>
                                {!notification.read && (
                                    <Button size="sm" onClick={(e) => {
                                        e.stopPropagation();
                                        handleMarkAsRead(notification.id);
                                    }}>
                                        Mark as Read
                                    </Button>
                                )}
                            </td>
                        </tr>
                    ))}
                </tbody>
            </Table>

            <Modal show={showModal} onHide={() => setShowModal(false)}>
                <Modal.Header closeButton>
                    <Modal.Title>Notification Details</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    {selectedNotification && (
                        <>
                            <p><strong>Date:</strong> {new Date(selectedNotification.date).toLocaleString()}</p>
                            <p><strong>Message:</strong> {selectedNotification.message}</p>
                            <p><strong>Status:</strong> {selectedNotification.read ? "Read" : "Unread"}</p>
                        </>
                    )}
                </Modal.Body>
            </Modal>
        </div>
    );
};

export default NotificationsPage;