import React, { useState, useEffect } from 'react';
import { 
    Table, TableBody, TableCell, TableContainer, TableHead, TableRow, 
    Paper, Alert, Skeleton, TextField, Button, Box 
} from '@mui/material';

const ATSScores = () => {
    const [applications, setApplications] = useState([]);
    const [loading, setLoading] = useState(false);
    const [error, setError] = useState(null);

    const [newApplication, setNewApplication] = useState({
        applicantName: '',
        position: '',
        score: ''
    });

    useEffect(() => {
        fetchATSScores();
    }, []);

    const fetchATSScores = async () => {
        setLoading(true);
        setError(null);
        try {
            const response = await fetch('https://pilotserver.vercel.app/api/v1/hr/ats-scores', {
                headers: { 
                    'Authorization': `Bearer ${localStorage.getItem('token')}` 
                }
            });

            if (!response.ok) {
                throw new Error(`Failed to fetch ATS scores. Status: ${response.status}`);
            }

            const data = await response.json();
            setApplications(data);
        } catch (error) {
            setError(error.message);
            console.error('Error fetching ATS scores:', error);
        } finally {
            setLoading(false);
        }
    };

    const handleInputChange = (event) => {
        const { name, value } = event.target;
        setNewApplication({
            ...newApplication,
            [name]: value
        });
    };

    const handleSubmit = async (event) => {
        event.preventDefault();
        setLoading(true);
        setError(null);
        try {
            const response = await fetch('https://pilotserver.vercel.app/api/v1/hr/ats-scores', {
                method: 'POST',
                headers: { 
                    'Content-Type': 'application/json',
                    'Authorization': `Bearer ${localStorage.getItem('token')}` 
                },
                body: JSON.stringify(newApplication)
            });

            if (!response.ok) {
                throw new Error(`Failed to create ATS score. Status: ${response.status}`);
            }

            setNewApplication({ applicantName: '', position: '', score: '' });
            fetchATSScores(); // Refresh the list
        } catch (error) {
            setError(error.message);
            console.error('Error creating ATS score:', error);
        } finally {
            setLoading(false);
        }
    };

    return (
        <Box sx={{ padding: 2 }}>
            <Box sx={{ marginBottom: 2 }}>
                <form onSubmit={handleSubmit}>
                    <TextField 
                        label="Applicant Name" 
                        name="applicantName" 
                        value={newApplication.applicantName} 
                        onChange={handleInputChange} 
                        required 
                        sx={{ marginRight: 1 }}
                    />
                    <TextField 
                        label="Position" 
                        name="position" 
                        value={newApplication.position} 
                        onChange={handleInputChange} 
                        required 
                        sx={{ marginRight: 1 }}
                    />
                    <TextField 
                        label="Score" 
                        name="score" 
                        type="number" 
                        value={newApplication.score} 
                        onChange={handleInputChange} 
                        required 
                        sx={{ marginRight: 1 }}
                    />
                    <Button type="submit" variant="contained" color="primary">
                        Add ATS Score
                    </Button>
                </form>
            </Box>

            <TableContainer component={Paper}>
                {loading ? (
                    <Table>
                        <TableHead>
                            <TableRow>
                                <TableCell>Applicant Name</TableCell>
                                <TableCell>Position</TableCell>
                                <TableCell>Score</TableCell>
                            </TableRow>
                        </TableHead>
                        <TableBody>
                            {Array.from(new Array(5)).map((_, index) => (
                                <TableRow key={index}>
                                    <TableCell><Skeleton variant="text" /></TableCell>
                                    <TableCell><Skeleton variant="text" /></TableCell>
                                    <TableCell><Skeleton variant="text" /></TableCell>
                                </TableRow>
                            ))}
                        </TableBody>
                    </Table>
                ) : error ? (
                    <Alert severity="error">{error}</Alert>
                ) : (
                    <Table>
                        <TableHead>
                            <TableRow>
                                <TableCell>Applicant Name</TableCell>
                                <TableCell>Position</TableCell>
                                <TableCell>Score</TableCell>
                            </TableRow>
                        </TableHead>
                        <TableBody>
                            {applications.map((application) => (
                                <TableRow key={application._id}>
                                    <TableCell>{application.applicantName}</TableCell>
                                    <TableCell>{application.position}</TableCell>
                                    <TableCell>{application.score}</TableCell>
                                </TableRow>
                            ))}
                        </TableBody>
                    </Table>
                )}
            </TableContainer>
        </Box>
    );
};

export default ATSScores;
