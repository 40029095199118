import React from 'react';
import { Link } from 'react-router-dom';
import '../styles/Footer.css';
import { FaFacebookF, FaTwitter, FaLinkedinIn, FaYoutube, FaInstagram } from 'react-icons/fa';
// import { colors } from '@material-ui/core';

const footerSections = [
    { title: 'Products', items: ['Platform Overview', 'AI / ML', 'Collaboration', 'Governance', 'All Features'] },
    { title: 'Solutions', items: ['By Industry', 'By Role', 'Integrations', 'Case Studies'] },
    { title: 'Resources', items: ['Blog', 'Documentation', 'eBooks', 'Webinars', 'Community'] },
    { title: 'Company', items: ['About Us', 'Careers', 'Press', 'Contact'] }
];

const Footer = () => {
    const handleSubscribe = async (e) => {
        e.preventDefault();
        const email = e.target.email.value;

        try {
            const response = await fetch('https://pilotserver.vercel.app/api/v1/subscribe', {  // Updated API versioning
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                },
                body: JSON.stringify({ email }),
            });

            const data = await response.json();

            if (response.ok) {
                alert('Thank you for subscribing! We will keep you updated.');
                e.target.reset();
            } else {
                alert(data.message || 'Subscription failed. Please try again.');
            }
        } catch (error) {
            console.error('Error subscribing:', error);
            alert('An unexpected error occurred. Please try again later.');
        }
    };

    return (
        <footer className="footer">
            <div className="footer-container">
                <div className="footer-brand">
                    <img src={require('../assets/favicon.png')} alt="Company Logo" className="footer-logo" />
                    <p className="footer-slogan">Empowering businesses with innovative solutions</p>
                    <div className="footer-social">
                        <a href="https://www.facebook.com/profile.php?id=61562671923171" aria-label="Facebook"><FaFacebookF /></a>
                        <a href="https://x.com/pilomation" aria-label="Twitter"><FaTwitter /></a>
                        <a href="https://www.linkedin.com/company/pilomation/" aria-label="LinkedIn"><FaLinkedinIn /></a>
                        <a href="https://www.youtube.com/@Pilomation" aria-label="YouTube"><FaYoutube /></a>
                        <a href="https://www.instagram.com/pilomations/" aria-label="Instagram"><FaInstagram /></a>
                    </div>
                </div>
                
                <div className="footer-columns">
                    <div className="footer-column">
                        {footerSections.slice(0, 2).map((section, index) => (
                            <div className="footer-section" key={index}>
                                <h3>{section.title}</h3>
                                <ul>
                                    {section.items.map((item, idx) => (
                                        <li key={idx}>
                                            <Link to={item === 'Contact' || item === 'Careers' || item === 'About' ? `/${item.toLowerCase().replace(/ /g, '-')}` : `/footer/${item.toLowerCase().replace(/ /g, '-')}`}>
                                                {item}
                                            </Link>
                                        </li>
                                    ))}
                                </ul>
                            </div>
                        ))}
                    </div>
                    <div className="footer-column">
                        {footerSections.slice(2, 4).map((section, index) => (
                            <div className="footer-section" key={index}>
                                <h3>{section.title}</h3>
                                <ul>
                                    {section.items.map((item, idx) => (
                                        <li key={idx}>
                                            <Link to={item === 'Contact' || item === 'Careers' || item === 'About Us' ? `/${item.toLowerCase().replace(/ /g, '-')}` : `/footer/${item.toLowerCase().replace(/ /g, '-')}`}>
                                                {item}
                                            </Link>
                                        </li>
                                    ))}
                                </ul>
                            </div>
                        ))}
                    </div>
                </div>

                <div className="footer-newsletter">
                    <h3>Stay Updated</h3>
                    <form className="newsletter-form" onSubmit={handleSubscribe}>
                        <input type="email" name="email" placeholder="Enter your email" required />
                        <button type="submit">Subscribe</button>
                    </form>
                </div>

                <div className="footer-legal">
                    <p style={{ color: "white" }}>&copy; {new Date().getFullYear()} Pilomation. All rights reserved.</p>
                    <ul>
                        <li><Link to="/privacy-policy">Privacy Policy</Link></li>
                        <li><Link to="/terms-of-service">Terms of Service</Link></li>
                        <li><Link to="/cookies-policy">Cookie Policy</Link></li>
                    </ul>
                </div>
            </div>
        </footer>
    );
};

export default Footer;
