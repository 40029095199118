import React from 'react';
import '../styles/Portfolio.css';

// Assuming you have these images in your project
import aiMlImage from '../assets/Ai.png';
import cloudServicesImage from '../assets/cloud.jpeg';
import dataAnalyticsImage from '../assets/data-analytics.jpg';
import automationImage from '../assets/automation.jpg';
import cybersecurityImage from '../assets/cybersecurity.jpg';
import iotImage from '../assets/iot.png';

const portfolioItems = [
    {
        title: 'AI & Machine Learning',
        description: 'Cutting-edge AI solutions for business intelligence and process optimization.',
        image: aiMlImage,
    },
    {
        title: 'Cloud Services',
        description: 'Scalable and secure cloud infrastructure for modern enterprises.',
        image: cloudServicesImage,
    },
    {
        title: 'Data Analytics',
        description: 'Transform raw data into actionable insights for informed decision-making.',
        image: dataAnalyticsImage,
    },
    {
        title: 'Automation Solutions',
        description: 'Streamline operations with our advanced automation technologies.',
        image: automationImage,
    },
    {
        title: 'Cybersecurity',
        description: 'Robust security measures to protect your digital assets and data.',
        image: cybersecurityImage,
    },
    {
        title: 'IoT Integration',
        description: 'Connecting devices and systems for smarter, data-driven operations.',
        image: iotImage,
    },
];

const Portfolio = () => {
    return (
        <div className="portfolio-container">
            <h1>Our Portfolio</h1>
            <p className="portfolio-intro">
                Explore our innovative solutions that are transforming businesses across industries.
                At Pilomation, we combine cutting-edge technology with deep industry expertise to deliver
                unparalleled value to our clients.
            </p>
            <div className="portfolio-grid">
                {portfolioItems.map((item, index) => (
                    <div key={index} className="portfolio-item">
                        <img src={item.image} alt={item.title} className="portfolio-image" />
                        <div className="portfolio-overlay">
                            <h3>{item.title}</h3>
                            <p>{item.description}</p>
                            <button className="learn-more-btn">Learn More</button>
                        </div>
                    </div>
                ))}
            </div>
            <div className="cta-section">
                <h2>Ready to Transform Your Business?</h2>
                <p>Let's discuss how Pilomation can help you achieve your goals.</p>
                <button className="contact-btn" onClick={() => window.location.href = '/contact'}>Contact Us</button>
            </div>
        </div>
    );
};

export default Portfolio;