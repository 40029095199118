import React from 'react';
import '../styles/About.css';
import overviewimage from '../assets/banner1.gif';
import binitBhushanImage from '../assets/binitBhushanImage.png';
import globalAmbitionImage from '../assets/globalAmbitionImage.gif';
import futureGrowthImage from '../assets/futureGrowthImage.png';
import productsImage from '../assets/productsImage.jpg';
import careersCultureImage from '../assets/careersCultureImage.jpg';
// import Footer from '../components/Footer'; 

const About = () => {
    return (
        <div className="about-page">
            <h1>About Pilomation</h1>

            <section className="company-overview">
                <img src={overviewimage} alt="Pilomation Overview" className="overview-image" />
                <h2>Pilomation: Pioneering the Future of Automation</h2>
                <p>Founded in 2024, Pilomation is at the forefront of the AI-driven automation revolution. As a dynamic startup, our mission is to transform business operations through innovative software solutions powered by artificial intelligence. We're not just automating processes; we're reimagining them, creating efficient, scalable, and intelligent systems that drive business growth and innovation.</p>
                <p>Despite being new to the scene, our team of passionate engineers, data scientists, and industry experts is committed to pushing the boundaries of what's possible in business automation. We're here to make a significant impact across various sectors, from finance and healthcare to manufacturing and beyond.</p>
            </section>

            <section className="leadership">
                <h2>Visionary Leadership</h2>
                <div className="leader">
                    <img src={binitBhushanImage} alt="Binit Bhushan" className="leader-image" />
                    <h3>Binit Bhushan</h3>
                    <p>Founder and CEO</p>
                    <p>A visionary entrepreneur with a passion for AI and automation, Binit Bhushan founded Pilomation with the goal of democratizing access to advanced automation technologies. His innovative approach and fresh perspective are driving Pilomation's rapid growth and establishing its position as an emerging industry leader.</p>
                </div>
                {/* Add more leadership members as needed */}
            </section>

            <section className="global-ambition">
                <img src={globalAmbitionImage} alt="Pilomation's Global Ambition" className="ambition-image" />
                <h2>Global Ambition, Local Roots</h2>
                <p>While we're starting our journey from our headquarters in [Your City], our vision is global. We're building a diverse team of professionals who collaborate remotely from various parts of the world. This approach allows us to tap into a global talent pool and bring together innovative minds regardless of geographical boundaries.</p>
                <p>Our aim is to establish key offices in tech hubs like San Francisco, New York, London, and Bangalore as we grow, fostering a truly global perspective in our approach to problem-solving.</p>
            </section>

            <section className="future-growth">
                <img src={futureGrowthImage} alt="Pilomation's Future Growth" className="growth-image" />
                <h2>Positioned for Rapid Growth</h2>
                <p>As a new player in the AI and automation space, Pilomation is actively seeking partnerships and investment opportunities. Our innovative approach and cutting-edge solutions have already garnered interest from forward-thinking investors and potential clients.</p>
                <p>We're laying the groundwork for significant expansion, with plans to scale our operations and impact in the coming years. Our goal is to become a major disruptor in the automation industry, driving innovation and efficiency across multiple sectors.</p>
            </section>

            <section className="product-offerings">
                <img src={productsImage} alt="Pilomation's Product Offerings" className="products-image" />
                <h2>Innovative Solutions</h2>
                <p>Our product suite represents the next generation of AI-driven automation technology. We're developing advanced solutions for:</p>
                <ul>
                    <li>Intelligent Data Extraction: Harnessing AI to process and analyze unstructured data from diverse sources.</li>
                    <li>Adaptive Workflow Automation: Creating smart systems that learn and improve your business processes over time.</li>
                    <li>AI Integration Platforms: Developing tools to seamlessly incorporate AI models into existing business operations.</li>
                </ul>
                <p>These products are currently in development, with beta versions slated for release in the near future. We're excited to work closely with early adopters to refine and perfect our offerings.</p>
            </section>

            <section className="careers-culture">
                <img src={careersCultureImage} alt="Careers and Culture at Pilomation" className="careers-image" />
                <h2>Join Our Innovation Journey</h2>
                <p>At Pilomation, we're building more than just a company; we're cultivating a community of innovators passionate about shaping the future of work. Our culture celebrates creativity, embraces challenges, and rewards bold thinking.</p>
                <p>As a startup, we offer unique opportunities for professionals to make a significant impact and grow with the company. Whether you're an experienced tech professional or a promising talent just starting your career, Pilomation provides a platform to work on cutting-edge technologies and contribute to reshaping global business practices.</p>
                <p>Join us in our mission to redefine what's possible in business automation and AI.</p>
            </section>

            {/* <footer className="about-footer">
                <p>&copy; {new Date().getFullYear()} Pilomation, Inc. All rights reserved.</p>
            </footer> */}
        </div>
    );
}

export default About;
