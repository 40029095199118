import React, { useState, useEffect } from 'react';
import { Link, useParams } from 'react-router-dom';
import '../styles/ForgotPasswordPage.css';

const ForgotPasswordPage = () => {
    const { token } = useParams(); // Get the token from the URL if it exists
    const [email, setEmail] = useState('');
    const [newPassword, setNewPassword] = useState('');
    const [confirmPassword, setConfirmPassword] = useState('');
    const [isLoading, setIsLoading] = useState(false);
    const [successMessage, setSuccessMessage] = useState('');
    const [errors, setErrors] = useState([]);
    const [isReset, setIsReset] = useState(false);

    useEffect(() => {
        if (token) {
            setIsReset(true); // If a token is present, switch to reset password mode
        }
    }, [token]);

    const handleRequestReset = async (e) => {
        e.preventDefault();
        setIsLoading(true);
        setErrors([]);
        setSuccessMessage('');

        try {
            const response = await fetch(`${process.env.REACT_APP_API_URL || 'https://www.pilomation.com'}/api/auth/forgot-password`, {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                },
                body: JSON.stringify({ email }),
            });

            const data = await response.json();

            if (response.ok) {
                setSuccessMessage('If an account exists for this email, a password reset link has been sent.');
                setEmail('');
            } else {
                setErrors(data.errors || ['An error occurred. Please try again.']);
            }
        } catch (error) {
            console.error('Error during password reset request:', error);
            setErrors(['An unexpected error occurred. Please try again later.']);
        } finally {
            setIsLoading(false);
        }
    };

    const handleResetPassword = async (e) => {
        e.preventDefault();
        if (newPassword !== confirmPassword) {
            setErrors(['Passwords do not match']);
            return;
        }

        setIsLoading(true);
        setErrors([]);
        setSuccessMessage('');

        try {
            const response = await fetch(`${process.env.REACT_APP_API_URL || 'http://localhost:2030'}/api/auth/reset-password`, {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                },
                body: JSON.stringify({ token, newPassword }),
            });

            const data = await response.json();

            if (response.ok) {
                setSuccessMessage('Your password has been reset successfully.');
                setNewPassword('');
                setConfirmPassword('');
            } else {
                setErrors(data.errors || ['An error occurred. Please try again.']);
            }
        } catch (error) {
            console.error('Error during password reset:', error);
            setErrors(['An unexpected error occurred. Please try again later.']);
        } finally {
            setIsLoading(false);
        }
    };

    return (
        <div className="forgot-password-container">
            <div className="forgot-password-form">
                <img src={require('../assets/favicon.png')} alt="Pilomation Logo" className="logo" />
                <h2>{isReset ? 'Reset Password' : 'Forgot Password'}</h2>
                <p>{isReset ? 'Enter your new password below.' : "Enter your email address and we'll send you a link to reset your password."}</p>
                <form onSubmit={isReset ? handleResetPassword : handleRequestReset}>
                    {successMessage && <div className="success-message">{successMessage}</div>}
                    {errors.length > 0 && (
                        <div className="error-messages">
                            {errors.map((error, index) => (
                                <div key={index} className="error-message">{error}</div>
                            ))}
                        </div>
                    )}
                    {!isReset && (
                        <div className="input-group">
                            <label htmlFor="email">Email</label>
                            <input
                                type="email"
                                id="email"
                                name="email"
                                value={email}
                                onChange={(e) => setEmail(e.target.value)}
                                required
                            />
                        </div>
                    )}
                    {isReset && (
                        <>
                            <div className="input-group">
                                <label htmlFor="newPassword">New Password</label>
                                <input
                                    type="password"
                                    id="newPassword"
                                    name="newPassword"
                                    value={newPassword}
                                    onChange={(e) => setNewPassword(e.target.value)}
                                    required
                                />
                            </div>
                            <div className="input-group">
                                <label htmlFor="confirmPassword">Confirm Password</label>
                                <input
                                    type="password"
                                    id="confirmPassword"
                                    name="confirmPassword"
                                    value={confirmPassword}
                                    onChange={(e) => setConfirmPassword(e.target.value)}
                                    required
                                />
                            </div>
                        </>
                    )}
                    <button type="submit" className="reset-button" disabled={isLoading}>
                        {isLoading ? 'Sending...' : isReset ? 'Reset Password' : 'Send Reset Link'}
                    </button>
                </form>
                {!isReset && (
                    <div className="links">
                        <Link to="/login">Back to Login</Link>
                        <Link to="/signup">Create an Account</Link>
                    </div>
                )}
            </div>
        </div>
    );
};

export default ForgotPasswordPage;
