import React from 'react';
import '../styles/BlogPage.css';
import logo from '../assets/favicon.png';
import benefitImage1 from '../assets/value1.jpg';
import benefitImage2 from '../assets/value2.png';
import benefitImage3 from '../assets/value4.jpg';

const Benefits = () => {
    return (
        <div className="page benefits-page">
            <header className="benefits-header">
                <img src={logo} alt="Pilomation Logo" className="logo" />
                <div className="header-text">
                    <h1>Benefits of Working at Pilomation</h1>
                    <p>Discover what makes Pilomation a great place to work!</p>
                </div>
            </header>

            <div className="benefits-content">
                <div className="image-container">
                    <img src={benefitImage1} alt="Benefit 1" className="benefit-image" />
                </div>
                <div className="text-container">
                    <h2>Comprehensive Benefits Package</h2>
                    <p>
                        We offer a competitive salary, health insurance, retirement plans, and paid time off. Our benefits package is designed to ensure that our employees and their families are well taken care of.
                    </p>
                </div>
            </div>

            <div className="benefits-content">
                <div className="text-container">
                    <h2>Positive Work Culture</h2>
                    <p>
                        At Pilomation, we foster a positive work environment where employees feel valued and supported. We encourage collaboration, creativity, and innovation in our teams.
                    </p>
                </div>
                <div className="image-container">
                    <img src={benefitImage2} alt="Benefit 2" className="benefit-image" />
                </div>
            </div>

            <div className="benefits-content">
                <div className="image-container">
                    <img src={benefitImage3} alt="Benefit 3" className="benefit-image" />
                </div>
                <div className="text-container">
                    <h2>Flexible Working Environment</h2>
                    <p>
                        We understand the importance of work-life balance. That's why we offer flexible working hours and remote work options to help our employees manage their personal and professional lives effectively.
                    </p>
                </div>
            </div>

            <footer className="benefits-footer">
                <img src={logo} alt="Pilomation Logo" className="footer-logo" />
                <p>© 2024 Pilomation</p>
            </footer>
        </div>
    );
};

export default Benefits;
