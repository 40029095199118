import React from 'react';
import { useParams } from 'react-router-dom';
import '../styles/ServicesDescription.css';

const serviceDescriptions = {
    100: {
        title: 'Cloud Services',
        description: 'Scalable cloud infrastructure solutions tailored to your business needs.',
        points: [
            'Flexible and secure cloud storage',
            'Customizable infrastructure solutions',
            '24/7 support and monitoring',
            'Multi-cloud and hybrid cloud strategies'
        ],
        strategy: 'Our cloud strategy focuses on optimizing performance, reducing costs, and ensuring scalability. We help businesses transition smoothly to the cloud, leveraging its full potential.',
        importance: [
            'Reduces capital expenses on hardware',
            'Enables rapid scaling and deployment',
            'Improves data accessibility and collaboration',
            'Enhances disaster recovery and business continuity'
        ],
        approach: 'We begin with a thorough assessment of your current infrastructure, design a tailored cloud solution, and manage the migration process with minimal disruption to your operations.',
        pilomationAdvantage: 'Pilomation\'s cloud services stand out with our proprietary optimization algorithms, ensuring maximum efficiency and cost-effectiveness for your cloud infrastructure.'
    },
    101: {
        title: 'AI Solutions',
        description: 'Cutting-edge artificial intelligence solutions to drive innovation and efficiency.',
        points: [
            'Machine learning algorithms',
            'Natural language processing',
            'Predictive analytics',
            'Computer vision and image recognition'
        ],
        strategy: 'Our AI strategy is centered on creating practical, business-focused solutions that deliver tangible ROI. We integrate AI seamlessly into existing processes to enhance productivity and decision-making.',
        importance: [
            'Automates repetitive tasks, freeing up human resources',
            'Provides data-driven insights for better decision making',
            'Enhances customer experiences through personalization',
            'Identifies patterns and trends invisible to human analysis'
        ],
        approach: 'We start by identifying key areas where AI can make the most impact, develop proof-of-concept models, and then scale these solutions across your organization.',
        pilomationAdvantage: 'Pilomation\'s AI solutions are built on our advanced proprietary algorithms, offering unparalleled accuracy and adaptability to your specific business context.'
    },
    102: {
        title: 'Network Security',
        description: 'Comprehensive network security solutions to protect your digital assets.',
        points: [
            'Firewall implementation and management',
            'Intrusion detection and prevention systems',
            'Vulnerability assessments and remediation',
            'End-to-end encryption protocols'
        ],
        strategy: 'Our network security strategy is built on a proactive approach, constantly evolving to stay ahead of emerging threats. We focus on creating robust, multi-layered defense systems.',
        importance: [
            'Protects sensitive data from breaches and cyber attacks',
            'Ensures compliance with data protection regulations',
            'Maintains customer trust and company reputation',
            'Prevents financial losses due to security incidents'
        ],
        approach: 'We conduct thorough security audits, implement tailored security measures, and provide ongoing monitoring and threat intelligence to keep your network secure.',
        pilomationAdvantage: 'Pilomation\'s network security solutions feature AI-powered threat detection, providing real-time protection against even the most sophisticated cyber attacks.'
    },
    103: {
        title: 'Mobile App Development',
        description: 'End-to-end mobile application development services for iOS and Android platforms.',
        points: [
            'Native and cross-platform app development',
            'UI/UX design and prototyping',
            'Backend integration and API development',
            'App store optimization and deployment'
        ],
        strategy: 'Our mobile app strategy focuses on creating intuitive, high-performance apps that provide real value to users. We emphasize user-centric design and robust functionality.',
        importance: [
            'Increases customer engagement and loyalty',
            'Provides a new channel for marketing and sales',
            'Improves brand visibility and recognition',
            'Enables data collection for business insights'
        ],
        approach: 'We follow an agile development process, starting with detailed requirements gathering, moving through iterative development cycles, and finishing with thorough testing and deployment.',
        pilomationAdvantage: 'Pilomation\'s mobile apps stand out with our advanced AI integration, providing personalized user experiences and predictive functionalities.'
    },
    104: {
        title: 'DevOps',
        description: 'Streamlined development and operations practices to accelerate software delivery.',
        points: [
            'Continuous integration and deployment (CI/CD)',
            'Infrastructure automation',
            'Containerization and orchestration',
            'Performance monitoring and optimization'
        ],
        strategy: 'Our DevOps strategy aims to break down silos between development and operations, fostering a culture of collaboration and continuous improvement.',
        importance: [
            'Accelerates time-to-market for new features',
            'Improves software quality and reliability',
            'Increases operational efficiency',
            'Enables rapid response to market changes'
        ],
        approach: 'We assess your current processes, implement DevOps tools and practices, and provide training to ensure your team can maintain and improve the DevOps pipeline.',
        pilomationAdvantage: 'Pilomation\'s DevOps solutions include AI-driven predictive maintenance and auto-scaling, ensuring optimal performance and resource utilization.'
    },
    105: {
        title: 'Marketing Services',
        description: 'Strategic marketing solutions to enhance brand visibility and customer engagement.',
        points: [
            'Digital marketing campaigns',
            'SEO and SEM optimization',
            'Social media management',
            'Content marketing and brand storytelling'
        ],
        strategy: 'Our marketing strategy is data-driven, focusing on creating targeted campaigns that resonate with your audience and drive measurable results.',
        importance: [
            'Increases brand awareness and market share',
            'Drives customer acquisition and retention',
            'Provides valuable customer insights',
            'Maximizes return on marketing investment'
        ],
        approach: 'We begin with in-depth market research, develop a comprehensive marketing plan, execute multi-channel campaigns, and continuously optimize based on performance analytics.',
        pilomationAdvantage: 'Pilomation\'s marketing services leverage AI for predictive analytics and personalized content delivery, ensuring maximum impact for your marketing efforts.'
    },
    106: {
        title: 'Web Development',
        description: 'Customized web development services for responsive and interactive websites.',
        points: [
            'Frontend and backend development',
            'Content management systems (CMS)',
            'E-commerce solutions',
            'Progressive Web Apps (PWAs)'
        ],
        strategy: 'Our web development strategy focuses on creating scalable, user-friendly websites that provide excellent user experiences across all devices.',
        importance: [
            'Establishes a strong online presence',
            'Improves customer engagement and conversion rates',
            'Provides a platform for content marketing and SEO',
            'Enables data collection for business intelligence'
        ],
        approach: 'We follow a user-centered design process, develop using the latest web technologies, and ensure thorough testing and optimization for performance and SEO.',
        pilomationAdvantage: 'Pilomation\'s web development services include AI-powered chatbots and personalized user experiences, setting your website apart from the competition.'
    },
    107: {
        title: 'AI/ML Solutions',
        description: 'Advanced AI and machine learning solutions to extract insights and optimize processes.',
        points: [
            'Data modeling and analysis',
            'Predictive modeling and forecasting',
            'Natural language processing (NLP)',
            'Deep learning and neural networks'
        ],
        strategy: 'Our AI/ML strategy focuses on developing scalable, interpretable models that provide actionable insights and drive business value.',
        importance: [
            'Uncovers hidden patterns in large datasets',
            'Enables data-driven decision making',
            'Automates complex analytical tasks',
            'Provides predictive capabilities for proactive business strategies'
        ],
        approach: 'We start with defining clear business objectives, prepare and analyze your data, develop and train models, and deploy them into your existing systems with ongoing monitoring and refinement.',
        pilomationAdvantage: 'Pilomation\'s AI/ML solutions feature our proprietary ensemble learning techniques, providing unparalleled accuracy and adaptability across various domains.'
    },
};

const ServicesDescription = () => {
    const { serviceId } = useParams();
    const service = serviceDescriptions[serviceId];

    if (!service) {
        return <div>Service not found</div>;
    }

    return (
        <div className="services-description">
            <h2>{service.title}</h2>
            <p>{service.description}</p>
            
            <h3>Key Features:</h3>
            <ul>
                {service.points.map((point, index) => (
                    <li key={index}>{point}</li>
                ))}
            </ul>
            
            <h3>Our Strategy:</h3>
            <p>{service.strategy}</p>
            
            <h3>Why It's Important:</h3>
            <ul>
                {service.importance.map((point, index) => (
                    <li key={index}>{point}</li>
                ))}
            </ul>
            
            <h3>Our Approach:</h3>
            <p>{service.approach}</p>
            
            <h3>The Pilomation Advantage:</h3>
            <p>{service.pilomationAdvantage}</p>
            
            <button className="contact-button" onClick={() => window.location.href='/contact'}>
                Transform Your Business with {service.title}
            </button>
        </div>
    );
}

export default ServicesDescription;