import React, { useState, useEffect } from 'react';
import { Link } from 'react-router-dom';
import '../styles/BlogPage.css';

const BlogCard = ({ post }) => (
    <div className="blog-card">
        <h3>{post.title}</h3>
        <p>{post.content.split(' ').slice(0, 50).join(' ')}...</p>
        <Link to={`/${post.id}`}>Read More</Link>
    </div>
);

const Blogs = () => {
    const [blogPosts, setBlogPosts] = useState([]);
    const [currentSlides, setCurrentSlides] = useState({});

    useEffect(() => {
        const posts = [
            { id: 1, title: "Understanding React Hooks", category: "company", content: "Lorem ipsum dolor sit amet, consectetur adipiscing elit. Nulla convallis libero non felis fermentum, sed ullamcorper quam ultrices." },
            { id: 2, title: "Mastering CSS Grid Layouts", category: "company", content: "Pellentesque a felis vitae lorem bibendum posuere. Sed vel justo nec nisi tempor egestas in nec odio." },
            { id: 3, title: "SEO Tips for Beginners", category: "company", content: "Aenean ac leo placerat, feugiat eros sit amet, vehicula elit. Integer tincidunt, nisi id auctor malesuada." },
            { id: 4, title: "Culture in Modern Workplaces", category: "company", content: "Culture in the workplace is essential for the success and well-being of employees." },
            { id: 5, title: "Employee Benefits Overview", category: "company", content: "An overview of employee benefits and how they contribute to job satisfaction." },
            { id: 6, title: "Effective Communication Strategies", category: "company", content: "Effective communication is key to any successful organization." },
            { id: 7, title: "Building a Collaborative Team", category: "company", content: "Collaboration is vital for productivity and innovation in any team." },
            { id: 8, title: "Leadership in the Digital Age", category: "company", content: "Leadership styles have evolved significantly in the digital age." },
            { id: 9, title: "Navigating Corporate Culture", category: "company", content: "Understanding and navigating corporate culture is crucial for new employees." },
            { id: 10, title: "Innovative Product Design", category: "products", content: "Innovative design is the backbone of successful product development." },
            { id: 11, title: "Product Development Cycles", category: "products", content: "Understanding the different stages of product development is essential." },
            { id: 12, title: "User-Centered Design", category: "products", content: "User-centered design focuses on the needs and preferences of users." },
            { id: 13, title: "Product Testing and Feedback", category: "products", content: "Product testing and feedback are crucial for improving quality." },
            { id: 14, title: "Market Research Strategies", category: "products", content: "Market research helps in understanding the needs and preferences of customers." },
            { id: 15, title: "Prototyping Techniques", category: "products", content: "Prototyping is an essential step in product design and development." },
            { id: 16, title: "Sustainable Product Design", category: "products", content: "Sustainability in product design is becoming increasingly important." },
            { id: 17, title: "Launching a New Product", category: "products", content: "The process of launching a new product involves careful planning and execution." },
            { id: 18, title: "Product Life Cycle Management", category: "products", content: "Managing the product life cycle effectively ensures long-term success." },
            { id: 19, title: "NextGenAI Innovations", category: "nextgenai", content: "NextGenAI is leading the way in artificial intelligence innovations." },
            { id: 20, title: "AI in Healthcare", category: "nextgenai", content: "AI is transforming the healthcare industry in numerous ways." },
            { id: 21, title: "AI and Machine Learning", category: "nextgenai", content: "Machine learning is a subset of AI that focuses on algorithms and data." },
            { id: 22, title: "AI for Business Optimization", category: "nextgenai", content: "AI is being used to optimize various business processes." },
            { id: 23, title: "Ethical AI Practices", category: "nextgenai", content: "Ethical considerations are crucial in the development of AI technologies." },
            { id: 24, title: "AI in Financial Services", category: "nextgenai", content: "AI is revolutionizing the financial services industry." },
            { id: 25, title: "AI for Customer Support", category: "nextgenai", content: "AI is enhancing customer support through chatbots and virtual assistants." },
            { id: 26, title: "AI in Manufacturing", category: "nextgenai", content: "AI is improving efficiency and productivity in manufacturing." },
            { id: 27, title: "AI and Cybersecurity", category: "nextgenai", content: "AI is being used to enhance cybersecurity measures." },
            { id: 28, title: "AI in Retail", category: "nextgenai", content: "AI is transforming the retail industry through personalization and analytics." },
            { id: 29, title: "AI for Environmental Sustainability", category: "nextgenai", content: "AI is playing a role in promoting environmental sustainability." },
            { id: 30, title: "Design Thinking", category: "designs", content: "Design thinking is a methodology for creative problem solving." },
            { id: 31, title: "User Experience (UX) Design", category: "designs", content: "UX design focuses on creating products that provide meaningful experiences." },
            { id: 32, title: "Visual Design Principles", category: "designs", content: "Understanding visual design principles is essential for creating appealing designs." },
            { id: 33, title: "Design Systems", category: "designs", content: "Design systems help in maintaining consistency in product design." },
            { id: 34, title: "Responsive Design", category: "designs", content: "Responsive design ensures that products work well on various devices." },
            { id: 35, title: "Interaction Design", category: "designs", content: "Interaction design focuses on the interaction between users and products." },
            { id: 36, title: "Design for Accessibility", category: "designs", content: "Designing for accessibility ensures that products can be used by everyone." },
            { id: 37, title: "DevOps for Beginners", category: "devops", content: "An introduction to DevOps principles and practices." },
            { id: 38, title: "Continuous Integration", category: "devops", content: "Continuous integration is a DevOps practice that involves frequent code integrations." },
            { id: 39, title: "Continuous Delivery", category: "devops", content: "Continuous delivery ensures that code changes are automatically prepared for release." },
            { id: 40, title: "Infrastructure as Code", category: "devops", content: "Infrastructure as Code (IaC) is a key practice in DevOps." },
            { id: 41, title: "Monitoring and Logging", category: "devops", content: "Monitoring and logging are essential for maintaining system reliability." },
            { id: 42, title: "Automated Testing", category: "devops", content: "Automated testing helps in ensuring the quality of code." },
            { id: 43, title: "Containerization with Docker", category: "devops", content: "Docker is a popular tool for containerizing applications." },
            { id: 44, title: "Orchestration with Kubernetes", category: "devops", content: "Kubernetes is a tool for automating the deployment, scaling, and management of containerized applications." },
            { id: 45, title: "Cloud Automation", category: "devops", content: "Cloud automation involves using tools to automate cloud management tasks." },
            { id: 46, title: "Serverless Computing", category: "devops", content: "Serverless computing allows developers to build and run applications without managing servers." },
            { id: 47, title: "DevOps Tools Overview", category: "devops", content: "An overview of popular DevOps tools." },
            { id: 48, title: "CI/CD Pipelines", category: "devops", content: "CI/CD pipelines automate the process of software delivery." },
        ];

        setBlogPosts(posts);

        const initialSlides = {};
        categories.forEach(category => {
            initialSlides[category.key] = 0;
        });
        setCurrentSlides(initialSlides);
    }, []);

    const categories = [
        { name: "Company", key: "company" },
        { name: "Products", key: "products" },
        { name: "NextGenAI Solutions", key: "nextgenai" },
        { name: "Design Blogs", key: "designs" },
        { name: "DevOps Tools and Cloud Automation", key: "devops" },
    ];

    const handlePrev = (categoryKey) => {
        setCurrentSlides(prevSlides => ({
            ...prevSlides,
            [categoryKey]: prevSlides[categoryKey] === 0 ? blogPosts.filter(post => post.category === categoryKey).length - 1 : prevSlides[categoryKey] - 1
        }));
    };

    const handleNext = (categoryKey) => {
        setCurrentSlides(prevSlides => ({
            ...prevSlides,
            [categoryKey]: prevSlides[categoryKey] === blogPosts.filter(post => post.category === categoryKey).length - 1 ? 0 : prevSlides[categoryKey] + 1
        }));
    };

    return (
        <div className="blog-page">
            <header className="blog-header">
                <h1>Explore Our Blog</h1>
            </header>
            <div className="blog-sections">
                {categories.map(category => (
                    <div key={category.key} className="blog-section">
                        <h2>{category.name}</h2>
                        <div className="blog-grid">
                            {blogPosts.filter(post => post.category === category.key).map((post, index) => (
                                <div
                                    key={post.id}
                                    className={`blog-slide ${index === currentSlides[category.key] ? 'active' : ''}`}
                                    style={{ transform: `translateX(-${currentSlides[category.key] * 100}%)` }}
                                >
                                    <BlogCard post={post} />
                                </div>
                            ))}
                        </div>
                        <div className="nav-buttons">
                            <button onClick={() => handlePrev(category.key)} className="prev-button">←</button>
                            <button onClick={() => handleNext(category.key)} className="next-button">→</button>
                        </div>
                        <div className="dots">
                            {blogPosts.filter(post => post.category === category.key).map((_, index) => (
                                <span key={index} className={`dot ${index === currentSlides[category.key] ? 'active' : ''}`}></span>
                            ))}
                        </div>
                    </div>
                ))}
            </div>
            <footer className="blog-footer">
                <p>&copy; 2024 Pilomation. All rights reserved.</p>
            </footer>
        </div>
    );
};

export default Blogs;
