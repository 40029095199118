import React, { useEffect, useState } from 'react';
import '../styles/Robot.css';

const thoughts = [
    "Future AI will revolutionize industries!",
    "Automation is the key to efficiency.",
    "Embrace the power of machine learning.",
    "Pilomation: Innovating the future.",
    "Join us and transform your business.",
    "Smart solutions for a smarter world.",
];

const Robot = () => {
    const [thought, setThought] = useState("");

    useEffect(() => {
        const interval = setInterval(() => {
            const randomThought = thoughts[Math.floor(Math.random() * thoughts.length)];
            setThought("");
            setTimeout(() => {
                setThought(randomThought);
                const thoughtBubble = document.querySelector('.thought-bubble');
                thoughtBubble.style.transform = 'scale(1)';
                setTimeout(() => {
                    thoughtBubble.style.transform = 'scale(1.2)'; // Increase size after typing
                }, 8000); // Delay matching the typing animation duration
            }, 100); // Small delay before setting new thought
        }, 10000); // Change thought every 10 seconds

        return () => clearInterval(interval);
    }, []);

    const speakThought = (text) => {
        if ('speechSynthesis' in window) {
            const utterance = new SpeechSynthesisUtterance(text);
            utterance.voice = speechSynthesis.getVoices().find(voice => voice.name === "Google UK English Male") || speechSynthesis.getVoices()[0];
            utterance.rate = 0.9; // Adjust the rate of speech if needed
            speechSynthesis.speak(utterance);
        } else {
            console.error("SpeechSynthesis API is not supported in this browser.");
        }
    };

    return (
        <div 
            className="robot-container" 
            onMouseEnter={() => speakThought(thought)} // Speak out the thought on hover
        >
            <div className="robot">
                <div className="head">
                    <div className="eye left-eye"></div>
                    <div className="eye right-eye"></div>
                    <div className="mouth"></div>
                </div>
                <div className="pilot-cap"></div>
                <div className="body">
                    <div className="typewriter-text">Steps in next GenAI</div>
                </div>
                <div className="arms">
                    <div className="arm left-arm"></div>
                    <div className="arm right-arm wave-hand"></div>
                </div>
                <div className="legs">
                    <div className="leg left-leg"></div>
                    <div className="leg right-leg"></div>
                </div>
            </div>
            <div className="thought-bubble">
                <p className="thought-text">{thought}</p>
            </div>
        </div>
    );
};

export default Robot;
