import React from 'react';
import { useParams } from 'react-router-dom';
import '../styles/JobDetail.css';
import logo from '../assets/favicon.png';

const jobPostings = [
    {
        id: 1,
        title: "Software Engineer Intern",
        location: "San Francisco, CA, India (Remote)",
        description: "Develop and maintain software applications, collaborating with cross-functional teams to deliver high-quality products. This internship role is for a duration of 3 to 6 months and is unpaid.",
        details: `We are seeking a motivated and talented Software Engineer Intern to join our development team. 
        The intern will work on developing and maintaining software applications, collaborating with cross-functional teams to deliver high-quality products. 
        This internship offers a great opportunity to gain practical experience in software development within a dynamic and innovative environment.`,
        responsibilities: [
            "Develop, test, and maintain software applications using programming languages such as JavaScript, Python, or Java.",
            "Collaborate with cross-functional teams, including designers, product managers, and other engineers, to define and implement new features.",
            "Assist in the design and implementation of web applications using frameworks like React or Angular.",
            "Participate in code reviews to ensure code quality and adherence to coding standards.",
            "Debug and troubleshoot software issues, providing effective solutions in a timely manner.",
            "Write and maintain technical documentation for software applications and processes.",
            "Contribute to the continuous improvement of development processes and methodologies.",
            "Stay up-to-date with the latest industry trends and technologies to ensure the application of best practices."
        ],
        requirements: [
            "Strong knowledge of programming languages such as JavaScript, Python, or Java",
            "Experience with web development frameworks like React or Angular",
            "Understanding of version control systems like Git",
            "Excellent problem-solving and analytical skills",
            "Ability to work independently and in a team environment",
            "Good communication and collaboration skills",
            "Currently pursuing or recently completed a Bachelor's or Master's degree in Computer Science, Software Engineering, or related field"
        ],
        preferredQualifications: [
            "Previous internship or project experience in software development",
            "Knowledge of database systems such as MySQL, PostgreSQL, or MongoDB",
            "Familiarity with Agile development methodologies",
            "Experience with cloud platforms like AWS, Azure, or GCP",
            "Understanding of DevOps practices and tools"
        ],
        benefits: [
            "Hands-on experience in software development and project management",
            "Opportunity to work with a diverse and talented team",
            "Flexible working hours and remote work options",
            "Mentorship and career development opportunities",
            "Potential for full-time employment based on performance"
        ],
        locationInfo: "This position is available in San Francisco, CA, and India, with options for remote work. We welcome candidates from diverse locations who are capable of working effectively in a remote setup."
    }
    ,
    {
        id: 2,
        title: "Data Scientist Intern",
        location: "New York, NY, India (Remote)",
        description: "Analyze complex data sets to provide insights and drive data-driven decision-making across the organization.",
        details: `We are looking for a talented and motivated Data Scientist Intern to join our data science team. 
        The intern will analyze complex data sets, build predictive models, and provide insights to support data-driven decision-making across the organization. 
        This internship offers a valuable opportunity to gain practical experience in data science and analytics within a collaborative and innovative environment.`,
        responsibilities: [
            "Analyze large and complex data sets to extract meaningful insights.",
            "Develop and implement machine learning models to solve business problems.",
            "Create data visualizations and dashboards to communicate findings to stakeholders.",
            "Collaborate with cross-functional teams to understand data needs and deliver actionable insights.",
            "Clean and preprocess data to ensure data quality and accuracy.",
            "Conduct exploratory data analysis to identify trends and patterns.",
            "Write and maintain documentation for data processes and analyses.",
            "Stay updated with the latest advancements in data science and machine learning technologies."
        ],
        requirements: [
            "Proficiency in statistical analysis and data visualization tools",
            "Experience with machine learning algorithms and techniques",
            "Strong programming skills in languages such as Python or R",
            "Knowledge of database systems and SQL",
            "Ability to communicate complex findings to non-technical stakeholders",
            "Advanced degree in a relevant field (e.g., Statistics, Computer Science, Data Science)"
        ],
        preferredQualifications: [
            "Previous internship or project experience in data science or analytics",
            "Familiarity with big data technologies such as Hadoop or Spark",
            "Experience with data visualization tools like Tableau or Power BI",
            "Knowledge of cloud platforms like AWS, Azure, or GCP",
            "Understanding of data privacy and security principles"
        ],
        benefits: [
            "Hands-on experience in data science and machine learning",
            "Opportunity to work with a diverse and talented team",
            "Flexible working hours and remote work options",
            "Mentorship and career development opportunities",
            "Potential for full-time employment based on performance"
        ],
        locationInfo: "This position is available in New York, NY, and India, with options for remote work. We welcome candidates from diverse locations who are capable of working effectively in a remote setup."
    }
    ,
    {
        id: 3,
        title: "Project Manager Intern",
        location: "Remote",
        description: "Lead product development from ideation to launch, ensuring alignment with company goals and customer needs.",
        details: `We are seeking a highly motivated Project Manager Intern to join our team and assist in leading product development projects from ideation to launch. 
        The intern will work closely with cross-functional teams to ensure projects are aligned with company goals and meet customer needs. 
        This internship provides an excellent opportunity to gain hands-on experience in project management and product development within a dynamic and collaborative environment.`,
        responsibilities: [
            "Assist in the planning, execution, and delivery of product development projects.",
            "Coordinate with cross-functional teams to ensure project goals and timelines are met.",
            "Develop and maintain project plans, timelines, and status reports.",
            "Identify and mitigate risks and issues that may impact project success.",
            "Facilitate communication and collaboration among team members and stakeholders.",
            "Ensure projects are aligned with company goals and customer needs.",
            "Conduct market research and gather customer feedback to inform product development.",
            "Support the development and implementation of project management processes and tools."
        ],
        requirements: [
            "Proven experience in product management or related roles",
            "Strong understanding of user-centered design principles",
            "Ability to prioritize and manage multiple projects simultaneously",
            "Excellent communication and leadership skills",
            "Experience with agile development methodologies",
            "Bachelor's or Master's degree in a relevant field"
        ],
        preferredQualifications: [
            "Previous internship or project experience in project management or product development",
            "Familiarity with project management tools such as Jira, Trello, or Asana",
            "Knowledge of software development lifecycle and methodologies",
            "Strong analytical and problem-solving skills",
            "Ability to adapt to changing priorities and work in a fast-paced environment"
        ],
        benefits: [
            "Hands-on experience in project management and product development",
            "Opportunity to work with a diverse and talented team",
            "Flexible working hours and remote work options",
            "Mentorship and career development opportunities",
            "Potential for full-time employment based on performance"
        ],
        locationInfo: "This position is fully remote. We welcome candidates from diverse locations who are capable of working effectively in a remote setup."
    }
    ,
    {
        id: 4,
        title: "Digital Marketing Intern",
        location: "Austin, TX, India (Remote)",
        description: "Create and execute digital marketing campaigns, analyze performance metrics, and optimize strategies to drive customer engagement and brand awareness.",
        details: `We are looking for an enthusiastic and creative Digital Marketing Intern to join our marketing team. 
        The ideal candidate will assist in creating and executing digital marketing campaigns, analyzing performance metrics, and optimizing strategies to enhance customer engagement and brand awareness. 
        This internship offers an excellent opportunity to gain practical experience in digital marketing within a dynamic and collaborative environment.`,
        responsibilities: [
            "Assist in the development and implementation of digital marketing campaigns across various channels, including social media, email, and search engines.",
            "Monitor and analyze the performance of marketing campaigns, providing insights and recommendations for optimization.",
            "Create engaging content for social media, blog posts, email newsletters, and other marketing materials.",
            "Conduct market research to identify trends, target audiences, and competitive landscape.",
            "Collaborate with the design team to create visually appealing graphics and videos for marketing purposes.",
            "Manage and update the company’s website and social media profiles to ensure consistency and accuracy.",
            "Assist in the management of paid advertising campaigns, including Google Ads and social media ads.",
            "Track and report on key performance indicators (KPIs) and marketing metrics to measure campaign effectiveness.",
            "Support the planning and execution of marketing events and promotions.",
            "Stay up-to-date with the latest digital marketing trends and best practices."
        ],
        requirements: [
            "Experience in digital marketing strategies and tools",
            "Analytical mindset with the ability to interpret data and metrics",
            "Creative thinking and problem-solving skills",
            "Excellent communication and interpersonal skills",
            "Bachelor's degree in Marketing, Communications, or related field",
            "Familiarity with social media platforms and digital marketing tools such as Google Analytics, Hootsuite, or HubSpot",
            "Proficiency in Microsoft Office Suite and content management systems (CMS)",
            "Ability to work independently and as part of a team",
            "Strong organizational skills and attention to detail"
        ],
        preferredQualifications: [
            "Previous internship or project experience in digital marketing",
            "Knowledge of SEO and SEM best practices",
            "Experience with graphic design tools such as Adobe Creative Suite (Photoshop, Illustrator) or Canva",
            "Certifications in digital marketing (e.g., Google Analytics, Google Ads) are a plus"
        ],
        benefits: [
            "Hands-on experience in digital marketing and campaign management",
            "Opportunity to work with a diverse and talented team",
            "Flexible working hours and remote work options",
            "Mentorship and career development opportunities",
            "Competitive compensation and potential for full-time employment"
        ],
        locationInfo: "This position is available in Austin, TX, and India, with options for remote work. We welcome candidates from diverse locations who are capable of working effectively in a remote setup."
    }
    ,
    {
        id: 5,
        title: "Business Analyst Intern",
        location: "Austin, TX, India (Remote)",
        description: "Analyze business processes, gather requirements, and provide insights to support data-driven decision-making and improve operational efficiency.",
        details: `We are looking for a motivated and detail-oriented Business Analyst Intern to join our team. 
        The ideal candidate will be responsible for analyzing business processes, gathering requirements, and providing actionable insights to support data-driven decision-making and improve operational efficiency. 
        This internship offers an excellent opportunity to gain practical experience in business analysis within a dynamic and collaborative environment.`,
        responsibilities: [
            "Analyze business processes to identify areas for improvement and optimization.",
            "Gather and document business requirements from stakeholders and translate them into functional specifications.",
            "Conduct market research and competitive analysis to support strategic planning and decision-making.",
            "Develop and maintain dashboards and reports to monitor key performance indicators (KPIs) and business metrics.",
            "Collaborate with cross-functional teams to implement business solutions and ensure alignment with organizational goals.",
            "Assist in the development and execution of data collection and analysis methodologies.",
            "Participate in project meetings and provide updates on progress and findings.",
            "Prepare presentations and reports to communicate insights and recommendations to stakeholders.",
            "Support the implementation of process improvements and change management initiatives.",
            "Assist in conducting user acceptance testing (UAT) and ensuring the quality of deliverables."
        ],
        requirements: [
            "Strong analytical skills and attention to detail",
            "Ability to interpret complex data and trends",
            "Experience with business analysis techniques and tools",
            "Effective communication and presentation skills",
            "Bachelor's or Master's degree in Business Administration, Economics, or related field",
            "Proficiency with data analysis and visualization tools such as Excel, SQL, Tableau, or Power BI",
            "Strong problem-solving skills and ability to work independently",
            "Familiarity with project management methodologies is a plus",
            "Excellent organizational and time management skills"
        ],
        preferredQualifications: [
            "Previous internship or project experience in a business analysis role",
            "Knowledge of statistical analysis and modeling techniques",
            "Experience with data extraction and manipulation using programming languages such as Python or R",
            "Certifications in business analysis (e.g., CBAP, CCBA) are a plus"
        ],
        benefits: [
            "Hands-on experience in business analysis and data-driven decision-making",
            "Opportunity to work with a diverse and talented team",
            "Flexible working hours and remote work options",
            "Mentorship and career development opportunities",
            "Competitive compensation and potential for full-time employment"
        ],
        locationInfo: "This position is available in Austin, TX, and India, with options for remote work. We welcome candidates from diverse locations who are capable of working effectively in a remote setup."
    }
    ,
    {
        id: 6,
        title: "HR Manager Intern",
        location: "Los Angeles, CA, India (Remote)",
        description: "Assist in recruitment, employee relations, and administrative tasks to support the HR department.",
        details: `We are seeking a proactive and enthusiastic HR Manager Intern to join our team. 
        The successful candidate will assist in various HR functions, including recruitment, employee relations, and administrative tasks. 
        This role is an excellent opportunity for individuals looking to gain hands-on experience in Human Resources within a dynamic and supportive environment.`,
        responsibilities: [
            "Assist in the recruitment process, including posting job openings, screening resumes, scheduling interviews, and conducting reference checks.",
            "Support employee relations by addressing employee inquiries, facilitating conflict resolution, and promoting a positive work environment.",
            "Assist with onboarding and orientation of new hires to ensure a smooth integration into the company.",
            "Help maintain employee records and ensure all documentation is up-to-date and accurate.",
            "Assist in the development and implementation of HR policies and procedures.",
            "Support the planning and execution of employee engagement activities and events.",
            "Assist in conducting HR-related training sessions and workshops.",
            "Participate in HR projects and initiatives aimed at improving HR processes and practices.",
            "Handle confidential information with the utmost discretion and professionalism.",
            "Provide administrative support to the HR department, including preparing reports, maintaining HR databases, and other clerical tasks."
        ],
        requirements: [
            "Knowledge of HR functions and best practices",
            "Excellent interpersonal and communication skills",
            "Ability to handle confidential information with discretion",
            "Detail-oriented and organized approach to work",
            "Bachelor's or Master's degree in Human Resources or related field",
            "Strong problem-solving skills and ability to work independently",
            "Proficiency with Microsoft Office Suite (Word, Excel, PowerPoint)",
            "Familiarity with HR software and tools is a plus",
            "Ability to work in a fast-paced environment and manage multiple tasks simultaneously"
        ],
        preferredQualifications: [
            "Previous internship or project experience in an HR role",
            "Knowledge of labor laws and regulations",
            "Experience with HRIS (Human Resources Information System) software",
            "Certifications in HR (e.g., SHRM-CP, PHR) are a plus"
        ],
        benefits: [
            "Hands-on experience in various HR functions",
            "Opportunity to work with a diverse and talented team",
            "Flexible working hours and remote work options",
            "Mentorship and career development opportunities",
            "Competitive compensation and potential for full-time employment"
        ],
        locationInfo: "This position is available in Los Angeles, CA, and India, with options for remote work. We welcome candidates from diverse locations who are capable of working effectively in a remote setup."
    }
    ,
    {
        id: 7,
        title: "DevOps Engineer Intern",
        location: "Seattle, WA, India (Remote)",
        description: "Manage and automate deployment processes, ensuring smooth operation of software applications in production.",
        details: `We are seeking a motivated and enthusiastic DevOps Engineer Intern to join our dynamic team. 
        The successful candidate will play a key role in managing and automating deployment processes, ensuring the smooth operation of software applications in production. 
        This is an excellent opportunity for someone looking to gain hands-on experience in a fast-paced, technology-driven environment.`,
        responsibilities: [
            "Manage and automate deployment processes for various software applications to ensure reliability, efficiency, and scalability in production environments.",
            "Work with cloud platforms such as AWS, Azure, or GCP to manage and optimize cloud resources.",
            "Utilize containerization technologies like Docker and Kubernetes for application deployment and orchestration.",
            "Develop and maintain scripts using Bash, Python, or Ruby to automate system tasks and workflows.",
            "Design, implement, and maintain continuous integration and continuous deployment (CI/CD) pipelines to streamline software development and deployment.",
            "Collaborate with development, QA, and operations teams to ensure smooth and reliable deployment of software.",
            "Implement monitoring and logging solutions to ensure the health and performance of applications in production.",
            "Maintain clear and concise documentation of deployment processes, scripts, and infrastructure configurations.",
            "Assist in the development and improvement of infrastructure and processes to ensure security, scalability, and efficiency.",
            "Support and troubleshoot issues in the development, test, and production environments."
        ],
        requirements: [
            "Experience with cloud platforms like AWS, Azure, or GCP",
            "Knowledge of containerization technologies (Docker, Kubernetes)",
            "Proficiency in scripting languages such as Bash, Python, or Ruby",
            "Understanding of CI/CD pipelines and automation tools",
            "Bachelor's or Master's degree in Computer Science or related field",
            "Strong problem-solving skills and attention to detail",
            "Excellent communication and collaboration skills",
            "Ability to work in a fast-paced environment and manage multiple tasks simultaneously"
        ],
        preferredQualifications: [
            "Relevant certifications in cloud platforms (e.g., AWS Certified DevOps Engineer, Azure DevOps Engineer Expert) are a plus.",
            "Previous internship or project experience in a DevOps role.",
            "Familiarity with infrastructure as code (IaC) tools such as Terraform or Ansible.",
            "Knowledge of monitoring and logging tools such as Prometheus, Grafana, or ELK stack.",
            "Experience with version control systems like Git."
        ],
        benefits: [
            "Hands-on experience with cutting-edge technologies in the DevOps field",
            "Opportunity to work with a talented and diverse team",
            "Flexible working hours and remote work options",
            "Mentorship and career development opportunities",
            "Competitive compensation and potential for full-time employment"
        ],
        locationInfo: "This position is available in Seattle, WA, and India, with options for remote work. We welcome candidates from diverse locations who are capable of working effectively in a remote setup."
    },
    {
        id: 8,
        title: "Backend Engineer Intern",
        location: "Remote",
        description: "Develop server-side logic, ensuring high performance and responsiveness to requests from the front-end.",
        details: `We are looking for a dedicated Backend Engineer Intern to join our remote team. The intern will be responsible for developing server-side logic, optimizing the backend infrastructure, and ensuring high performance and responsiveness to front-end requests. 
        This role offers a great opportunity to gain practical experience in backend development and work on impactful projects within a supportive and innovative environment.`,
        responsibilities: [
            "Develop and maintain server-side logic for web applications.",
            "Optimize backend performance to ensure high responsiveness.",
            "Design and implement APIs to support front-end functionality.",
            "Collaborate with front-end developers to integrate user-facing elements with server-side logic.",
            "Work with database technologies to manage data storage and retrieval.",
            "Ensure the scalability and reliability of backend services.",
            "Identify and fix bugs and performance bottlenecks.",
            "Contribute to the design and implementation of microservices architecture."
        ],
        requirements: [
            "Proficiency in server-side languages such as Java, Python, or Node.js",
            "Experience with database technologies (SQL, NoSQL)",
            "Knowledge of API design and development",
            "Understanding of microservices architecture",
            "Bachelor's or Master's degree in Computer Science or related field"
        ],
        preferredQualifications: [
            "Previous internship or project experience in backend development",
            "Familiarity with cloud platforms such as AWS, Azure, or GCP",
            "Experience with containerization technologies like Docker and Kubernetes",
            "Strong problem-solving skills and attention to detail",
            "Ability to work independently and collaboratively in a remote team setup"
        ],
        benefits: [
            "Hands-on experience in backend development",
            "Opportunity to work with a talented and innovative team",
            "Flexible working hours and remote work options",
            "Mentorship and career development opportunities",
            "Potential for full-time employment based on performance"
        ],
        locationInfo: "This position is fully remote. We welcome candidates from diverse locations who are capable of working effectively in a remote setup."
    }
    ,
    {
        id: 9,
        title: "Technical Writer Intern",
        location: "Boston, MA, India (Remote)",
        description: "Create and maintain documentation for software applications, ensuring clarity and accessibility for users and developers.",
        details: `We are seeking a Technical Writer Intern to join our team. The intern will be responsible for creating and maintaining clear, concise, and comprehensive documentation for our software applications. This role involves collaborating with developers, product managers, and other stakeholders to ensure accurate and user-friendly documentation.`,
        responsibilities: [
            "Create and maintain user manuals, API documentation, and developer guides.",
            "Collaborate with developers and product managers to gather technical information.",
            "Translate complex technical concepts into clear and concise documentation.",
            "Use documentation tools and version control systems to manage and publish content.",
            "Review and edit content to ensure accuracy, clarity, and consistency.",
            "Stay updated on industry best practices and documentation standards."
        ],
        requirements: [
            "Excellent writing and editing skills",
            "Ability to translate technical information into clear and concise documentation",
            "Familiarity with documentation tools and version control systems",
            "Attention to detail and strong organizational skills",
            "Bachelor's or Master's degree in English, Technical Writing, or related field"
        ],
        preferredQualifications: [
            "Previous experience in technical writing or related roles",
            "Basic understanding of software development processes and technologies",
            "Experience with markdown, HTML, or other documentation markup languages",
            "Ability to work independently and meet deadlines",
            "Strong communication and collaboration skills"
        ],
        benefits: [
            "Hands-on experience in technical writing",
            "Opportunity to work with a talented and innovative team",
            "Flexible working hours and remote work options",
            "Mentorship and career development opportunities",
            "Potential for full-time employment based on performance"
        ],
        locationInfo: "This position is fully remote. We welcome candidates from diverse locations who are capable of working effectively in a remote setup."
    }
    ,
    {
        id: 10,
        title: "Quality Assurance (QA) Engineer Intern",
        location: "Chicago, IL, India (Remote)",
        description: "Test and ensure the quality of software applications, identifying and reporting bugs to the development team.",
        details: `We are looking for a Quality Assurance (QA) Engineer Intern to join our remote team. The intern will be responsible for testing software applications to ensure quality and functionality. This role involves identifying and reporting bugs, working closely with the development team to ensure timely resolution.`,
        responsibilities: [
            "Develop and execute test plans and test cases.",
            "Identify, document, and report bugs and issues.",
            "Collaborate with developers to resolve defects and ensure product quality.",
            "Perform functional, regression, and performance testing.",
            "Use testing tools and methodologies to ensure thorough coverage.",
            "Contribute to the continuous improvement of the testing process."
        ],
        requirements: [
            "Experience in software testing and quality assurance methodologies",
            "Knowledge of testing tools and techniques",
            "Ability to create detailed, comprehensive, and well-structured test plans and test cases",
            "Good communication and problem-solving skills",
            "Bachelor's or Master's degree in Computer Science or related field"
        ],
        preferredQualifications: [
            "Previous internship or project experience in QA or software testing",
            "Familiarity with automated testing tools and frameworks",
            "Basic understanding of software development processes",
            "Attention to detail and strong analytical skills",
            "Ability to work independently and meet deadlines"
        ],
        benefits: [
            "Hands-on experience in quality assurance",
            "Opportunity to work with a talented and innovative team",
            "Flexible working hours and remote work options",
            "Mentorship and career development opportunities",
            "Potential for full-time employment based on performance"
        ],
        locationInfo: "This position is fully remote. We welcome candidates from diverse locations who are capable of working effectively in a remote setup."
    }

];
const JobDetail = () => {
    const { id } = useParams();
    const job = jobPostings.find(job => job.id === parseInt(id));

    return (
        <div className="job-detail-page">
            <div className="content-wrap">
                <div className="job-detail-header">
                    <div className="job-role">
                        <h1>{job.title}</h1>
                    </div>
                    <a href="https://forms.office.com/r/imLEPuEEwd" target="_blank" rel="noopener noreferrer" className="apply-button-top">Apply-Now</a>
                </div>
                <div className="job-detail-content">
                    <p><strong>Location:</strong> {job.location}</p>
                    <p>{job.description}</p>
                    <p>{job.details}</p>
                    
                    <h2>Responsibilities:</h2>
                    <ul className="responsibilities-list">
                        {job.responsibilities.map((resp, index) => (
                            <li key={index}>{resp}</li>
                        ))}
                    </ul>

                    <h2>Requirements:</h2>
                    <ul className="requirements-list">
                        {job.requirements.map((req, index) => (
                            <li key={index}>{req}</li>
                        ))}
                    </ul>

                    <h2>Preferred Qualifications:</h2>
                    <ul className="preferred-qualifications-list">
                        {job.preferredQualifications.map((qual, index) => (
                            <li key={index}>{qual}</li>
                        ))}
                    </ul>

                    <h2>Benefits:</h2>
                    <ul className="benefits-list">
                        {job.benefits.map((benefit, index) => (
                            <li key={index}>{benefit}</li>
                        ))}
                    </ul>

                    <p><strong>Location Information:</strong> {job.locationInfo}</p>
                </div>
                <div className="apply-button-bottom-container">
                    <a href="https://forms.office.com/r/imLEPuEEwd" target="_blank" rel="noopener noreferrer" className="apply-button-bottom">Apply Now</a>
                </div>
            </div>
            <footer className="job-detail-footer">
                <div className="job-detail-footer-content">
                    <div className="job-detail-footer-logo">
                        <img src={logo} alt="Company Logo" />
                    </div>
                    <div className="job-detail-footer-links">
                        <ul>
                            <li><a href="/contact">Contact Us</a></li>
                            <li><a href="/blogs">Blogs</a></li>
                        </ul>
                    </div>
                </div>
                <div className="job-detail-footer-bottom">
                    <p>&copy; 2024 Pilomation. All rights reserved.</p>
                </div>
            </footer>
        </div>
    );
};


export default JobDetail;