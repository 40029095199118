import React, { useState, useEffect } from 'react';
import { Form, Button, Card, Alert } from 'react-bootstrap';

const SettingsPage = () => {
    const [settings, setSettings] = useState({
        notifications: true,
        darkMode: false,
        language: 'English',
        emailFrequency: 'daily',
        twoFactorAuth: false,
    });
    const [loading, setLoading] = useState(true);
    const [saveStatus, setSaveStatus] = useState(null);

    useEffect(() => {
        fetchSettings();
    }, []);

    const fetchSettings = async () => {
        // Simulated API call
        setLoading(true);
        const response = await fetch('/api/user/settings');
        const data = await response.json();
        setSettings(data);
        setLoading(false);
    };

    const handleChange = (event) => {
        const { name, value, type, checked } = event.target;
        setSettings(prevSettings => ({
            ...prevSettings,
            [name]: type === 'checkbox' ? checked : value
        }));
    };

    const handleSave = async (event) => {
        event.preventDefault();
        setSaveStatus('saving');
        try {
            // Simulated API call
            await fetch('/api/user/settings', {
                method: 'POST',
                headers: { 'Content-Type': 'application/json' },
                body: JSON.stringify(settings)
            });
            setSaveStatus('success');
        } catch (error) {
            setSaveStatus('error');
        }
    };

    if (loading) return <div>Loading settings...</div>;

    return (
        <div className="settings-page">
            <h1>User Settings</h1>
            <Card>
                <Card.Body>
                    <Form onSubmit={handleSave}>
                        <Form.Group className="mb-3">
                            <Form.Check
                                type="switch"
                                id="notifications"
                                name="notifications"
                                label="Enable Notifications"
                                checked={settings.notifications}
                                onChange={handleChange}
                            />
                        </Form.Group>

                        <Form.Group className="mb-3">
                            <Form.Check
                                type="switch"
                                id="darkMode"
                                name="darkMode"
                                label="Dark Mode"
                                checked={settings.darkMode}
                                onChange={handleChange}
                            />
                        </Form.Group>

                        <Form.Group className="mb-3">
                            <Form.Label>Language</Form.Label>
                            <Form.Select name="language" value={settings.language} onChange={handleChange}>
                                <option value="English">English</option>
                                <option value="Spanish">Spanish</option>
                                <option value="French">French</option>
                            </Form.Select>
                        </Form.Group>

                        <Form.Group className="mb-3">
                            <Form.Label>Email Frequency</Form.Label>
                            <Form.Select name="emailFrequency" value={settings.emailFrequency} onChange={handleChange}>
                                <option value="daily">Daily</option>
                                <option value="weekly">Weekly</option>
                                <option value="monthly">Monthly</option>
                            </Form.Select>
                        </Form.Group>

                        <Form.Group className="mb-3">
                            <Form.Check
                                type="switch"
                                id="twoFactorAuth"
                                name="twoFactorAuth"
                                label="Enable Two-Factor Authentication"
                                checked={settings.twoFactorAuth}
                                onChange={handleChange}
                            />
                        </Form.Group>

                        <Button variant="primary" type="submit">
                            Save Settings
                        </Button>
                    </Form>

                    {saveStatus === 'success' && (
                        <Alert variant="success" className="mt-3">
                            Settings saved successfully!
                        </Alert>
                    )}
                    {saveStatus === 'error' && (
                        <Alert variant="danger" className="mt-3">
                            An error occurred while saving settings. Please try again.
                        </Alert>
                    )}
                </Card.Body>
            </Card>
        </div>
    );
};

export default SettingsPage;