import React, { useState, useEffect, useCallback, useMemo } from 'react';
import { useNavigate } from 'react-router-dom';
import {
    FaUsers, FaFileAlt, FaBriefcase, FaCalendarAlt, FaUserPlus,
    FaEdit, FaChartLine, FaSearch, FaSignOutAlt, FaBell,
    FaEnvelope, FaChartBar, FaTasks, FaCog
} from 'react-icons/fa';
import {
    Container, Grid, Paper, Typography, Button, List, ListItem,
    ListItemText, CircularProgress, IconButton, Badge, Avatar,
    AppBar, Toolbar, Drawer, useTheme, useMediaQuery, Tabs, Tab,
    Menu, MenuItem
} from '@mui/material';
import { BarChart, Bar, XAxis, YAxis, CartesianGrid, Tooltip, Legend, ResponsiveContainer } from 'recharts';
import '../styles/HRDashboard.css';

import EmployeeManagement from '../components/EmployeeManagement'; // Correct path
import ATSScores from '../components/ATSscore'; // Correct path
import MeetingScheduler from '../components/MeetingScheduler'; // Correct path
import Messaging from '../components/Messaging'; // Correct path
import PerformanceReports from '../components/PerformanceReports'; // Correct path
import KanbanBoard from '../components/KanbanBoard'; // Correct path

const API_BASE_URL = 'https://pilotserver.vercel.app';

const HRDashboard = () => {
    const [hrData, setHRData] = useState(null);
    const [loading, setLoading] = useState(true);
    const [error, setError] = useState(null);
    const [searchTerm, setSearchTerm] = useState('');
    const [drawerOpen, setDrawerOpen] = useState(false);
    const [activeTab, setActiveTab] = useState(0);
    const [anchorEl, setAnchorEl] = useState(null);
    const navigate = useNavigate();
    const theme = useTheme();
    const isMobile = useMediaQuery(theme.breakpoints.down('md'));

    const handleLogout = useCallback(() => {
        localStorage.removeItem('token');
        localStorage.removeItem('userRole');
        navigate('/login');
    }, [navigate]);

    const fetchHRData = useCallback(async () => {
        const token = localStorage.getItem('token');
        if (!token) {
            setError('No authentication token found');
            setLoading(false);
            return;
        }

        try {
            const response = await fetch(`${API_BASE_URL}/api/v1/hr/dashboard`, {
                method: 'GET',
                headers: {
                    'Authorization': `Bearer ${token}`,
                    'Content-Type': 'application/json'
                }
            });

            if (response.status === 401) {
                throw new Error('Unauthorized access');
            }

            if (!response.ok) {
                const errorData = await response.json();
                throw new Error(errorData.message || 'Failed to fetch HR data');
            }

            const data = await response.json();
            setHRData(data);
        } catch (err) {
            console.error("Error fetching HR data:", err);
            setError(err.message);
            if (err.message === 'Unauthorized access') {
                handleLogout();
            }
        } finally {
            setLoading(false);
        }
    }, [handleLogout]);

    useEffect(() => {
        const userRole = localStorage.getItem('userRole');
        if (userRole !== 'hr') {
            navigate('/login');
        } else {
            fetchHRData();
        }
    }, [navigate, fetchHRData]);

    const chartData = useMemo(() => {
        if (!hrData) return [];
        const { metrics } = hrData;
        return [
            { name: 'Employees', value: metrics.totalEmployees },
            { name: 'Applications', value: metrics.totalApplications },
            { name: 'Open Positions', value: metrics.openPositions },
            { name: 'Interviews', value: metrics.upcomingInterviews },
            { name: 'Recent Hires', value: metrics.recentHires },
        ];
    }, [hrData]);

    const filteredActivities = useMemo(() => {
        const activities = [
            'New application received for Software Developer position',
            'Interview scheduled with John Doe for Project Manager role',
            'Onboarding completed for new Marketing Specialist',
            'Performance review initiated for IT Department',
            'New job posting created for Senior Data Analyst',
            'Employee benefits package updated'
        ];
        return activities.filter(activity =>
            activity.toLowerCase().includes(searchTerm.toLowerCase())
        );
    }, [searchTerm]);

    const handleMenuOpen = (event) => {
        setAnchorEl(event.currentTarget);
    };

    const handleMenuClose = () => {
        setAnchorEl(null);
    };

    if (loading) return <CircularProgress sx={{ position: 'absolute', top: '50%', left: '50%' }} />;
    if (error) return <Typography color="error" sx={{ textAlign: 'center', mt: 4 }}>{error}</Typography>;
    if (!hrData) return null;

    return (
        <div className="hr-dashboard">
            <AppBar position="static">
                <Toolbar>
                    <IconButton
                        edge="start"
                        color="inherit"
                        aria-label="menu"
                        sx={{ mr: 2 }}
                        onClick={() => setDrawerOpen(!drawerOpen)}
                    >
                        {isMobile ? <FaBriefcase /> : null}
                    </IconButton>
                    <Typography variant="h6" component="div" sx={{ flexGrow: 1 }}>
                        Pilomation HR Dashboard
                    </Typography>
                    <IconButton color="inherit">
                        <Badge badgeContent={4} color="secondary">
                            <FaBell />
                        </Badge>
                    </IconButton>
                    <IconButton color="inherit" onClick={handleMenuOpen}>
                        <Avatar sx={{ ml: 2 }}>{hrData.hrInfo.firstName[0]}</Avatar>
                    </IconButton>
                    <Menu
                        anchorEl={anchorEl}
                        open={Boolean(anchorEl)}
                        onClose={handleMenuClose}
                    >
                        <MenuItem onClick={handleMenuClose}>Profile</MenuItem>
                        <MenuItem onClick={handleMenuClose}>Settings</MenuItem>
                        <MenuItem onClick={handleLogout}>Logout</MenuItem>
                    </Menu>
                </Toolbar>
            </AppBar>

            <Drawer
                anchor="left"
                open={drawerOpen}
                onClose={() => setDrawerOpen(false)}
            >
                <Sidebar setActiveTab={setActiveTab} />
            </Drawer>

            <Container maxWidth="lg" sx={{ mt: 4, mb: 4 }}>
                <Grid container spacing={3}>
                    {!isMobile && <Sidebar setActiveTab={setActiveTab} />}
                    <Grid item xs={12} md={9} lg={10}>
                        <Typography variant="h4" gutterBottom>Welcome, {hrData.hrInfo.firstName}</Typography>
                        <Tabs value={activeTab} onChange={(e, newValue) => setActiveTab(newValue)} sx={{ mb: 2 }}>
                            <Tab label="Dashboard" />
                            <Tab label="Employees" />
                            <Tab label="ATS Scores" />
                            <Tab label="Meetings" />
                            <Tab label="Messages" />
                            <Tab label="Performance" />
                            <Tab label="Kanban" />
                        </Tabs>
                        {activeTab === 0 && (
                            <>
                                <DashboardWidgets hrData={hrData} />
                                <MetricsOverview chartData={chartData} />
                                <Grid container spacing={3}>
                                    <QuickActions />
                                    <RecentActivities
                                        activities={filteredActivities}
                                        searchTerm={searchTerm}
                                        setSearchTerm={setSearchTerm}
                                    />
                                </Grid>
                            </>
                        )}
                        {activeTab === 1 && <EmployeeManagement />}
                        {activeTab === 2 && <ATSScores />}
                        {activeTab === 3 && <MeetingScheduler />}
                        {activeTab === 4 && <Messaging />}
                        {activeTab === 5 && <PerformanceReports />}
                        {activeTab === 6 && <KanbanBoard />}
                    </Grid>
                </Grid>
            </Container>
        </div>
    );
};

const Sidebar = ({ setActiveTab }) => (
    <Grid item xs={12} md={3} lg={2}>
        <Paper sx={{ p: 2, display: 'flex', flexDirection: 'column' }}>
            <Button startIcon={<FaChartLine />} fullWidth sx={{ justifyContent: 'flex-start', mb: 1 }} onClick={() => setActiveTab(0)}>Dashboard</Button>
            <Button startIcon={<FaUsers />} fullWidth sx={{ justifyContent: 'flex-start', mb: 1 }} onClick={() => setActiveTab(1)}>Employees</Button>
            <Button startIcon={<FaFileAlt />} fullWidth sx={{ justifyContent: 'flex-start', mb: 1 }} onClick={() => setActiveTab(2)}>ATS Scores</Button>
            <Button startIcon={<FaCalendarAlt />} fullWidth sx={{ justifyContent: 'flex-start', mb: 1 }} onClick={() => setActiveTab(3)}>Meetings</Button>
            <Button startIcon={<FaEnvelope />} fullWidth sx={{ justifyContent: 'flex-start', mb: 1 }} onClick={() => setActiveTab(4)}>Messages</Button>
            <Button startIcon={<FaChartBar />} fullWidth sx={{ justifyContent: 'flex-start', mb: 1 }} onClick={() => setActiveTab(5)}>Performance</Button>
            <Button startIcon={<FaTasks />} fullWidth sx={{ justifyContent: 'flex-start', mb: 1 }} onClick={() => setActiveTab(6)}>Kanban Board</Button>
            <Button startIcon={<FaCog />} fullWidth sx={{ justifyContent: 'flex-start', mb: 1 }}>Settings</Button>
        </Paper>
    </Grid>
);

const DashboardWidgets = ({ hrData }) => (
    <Grid container spacing={3} sx={{ mb: 3 }}>
        <Grid item xs={12} sm={6} md={3}>
            <Paper sx={{ p: 2, display: 'flex', flexDirection: 'column', alignItems: 'center', bgcolor: 'primary.light' }}>
                <FaUsers size={24} color="white" />
                <Typography variant="h6" color="white">{hrData.metrics.totalEmployees}</Typography>
                <Typography variant="body2" color="white">Total Employees</Typography>
            </Paper>
        </Grid>
        <Grid item xs={12} sm={6} md={3}>
            <Paper sx={{ p: 2, display: 'flex', flexDirection: 'column', alignItems: 'center', bgcolor: 'secondary.light' }}>
                <FaFileAlt size={24} color="white" />
                <Typography variant="h6" color="white">{hrData.metrics.totalApplications}</Typography>
                <Typography variant="body2" color="white">Total Applications</Typography>
            </Paper>
        </Grid>
        <Grid item xs={12} sm={6} md={3}>
            <Paper sx={{ p: 2, display: 'flex', flexDirection: 'column', alignItems: 'center', bgcolor: 'success.light' }}>
                <FaBriefcase size={24} color="white" />
                <Typography variant="h6" color="white">{hrData.metrics.openPositions}</Typography>
                <Typography variant="body2" color="white">Open Positions</Typography>
            </Paper>
        </Grid>
        <Grid item xs={12} sm={6} md={3}>
            <Paper sx={{ p: 2, display: 'flex', flexDirection: 'column', alignItems: 'center', bgcolor: 'warning.light' }}>
                <FaCalendarAlt size={24} color="white" />
                <Typography variant="h6" color="white">{hrData.metrics.upcomingInterviews}</Typography>
                <Typography variant="body2" color="white">Upcoming Interviews</Typography>
            </Paper>
        </Grid>
    </Grid>
);

const MetricsOverview = ({ chartData }) => (
    <Paper sx={{ p: 2, display: 'flex', flexDirection: 'column', height: 300, mb: 3 }}>
        <Typography variant="h6" gutterBottom>Metrics Overview</Typography>
        <ResponsiveContainer width="100%" height="100%">
            <BarChart data={chartData}>
                <CartesianGrid strokeDasharray="3 3" />
                <XAxis dataKey="name" />
                <YAxis />
                <Tooltip />
                <Legend />
                <Bar dataKey="value" fill="#8884d8" />
            </BarChart>
        </ResponsiveContainer>
    </Paper>
);

const QuickActions = () => (
    <Grid item xs={12} md={4}>
        <Paper sx={{ p: 2, display: 'flex', flexDirection: 'column' }}>
            <Typography variant="h6" gutterBottom>Quick Actions</Typography>
            <Button variant="contained" color="primary" startIcon={<FaUserPlus />} sx={{ mb: 1 }}>Add Employee</Button>
            <Button variant="contained" color="secondary" startIcon={<FaEdit />} sx={{ mb: 1 }}>Post New Job</Button>
            <Button variant="contained" startIcon={<FaChartLine />}>Generate Report</Button>
        </Paper>
    </Grid>
);

const RecentActivities = ({ activities, searchTerm, setSearchTerm }) => (
    <Grid item xs={12} md={8}>
        <Paper sx={{ p: 2, display: 'flex', flexDirection: 'column' }}>
            <Typography variant="h6" gutterBottom>Recent Activities</Typography>
            <div style={{ display: 'flex', alignItems: 'center', marginBottom: '1rem' }}>
                <FaSearch style={{ marginRight: '0.5rem' }} />
                <input
                    type="text"
                    placeholder="Search activities..."
                    value={searchTerm}
                    onChange={(e) => setSearchTerm(e.target.value)}
                    style={{ flexGrow: 1, padding: '0.5rem' }}
                />
            </div>
            <List>
                {activities.map((activity, index) => (
                    <ListItem key={index}>
                        <ListItemText primary={activity} />
                    </ListItem>
                ))}
            </List>
        </Paper>
    </Grid>
);

export default HRDashboard;