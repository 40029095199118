import React, { useState, useEffect, useRef } from 'react';
import { Button, TextField, Dialog, DialogTitle, DialogContent, DialogActions, CircularProgress, Alert, List, ListItem, ListItemText } from '@mui/material';
import '../styles/MeetingScheduler.css';

const MeetingScheduler = () => {
    const [open, setOpen] = useState(false);
    const [meeting, setMeeting] = useState({ title: '', date: '', attendees: '', notes: '' });
    const [loading, setLoading] = useState(false);
    const [error, setError] = useState(null);
    const [success, setSuccess] = useState(false);
    const [scheduledMeetings, setScheduledMeetings] = useState([]);

    const hourHandRef = useRef(null);
    const minuteHandRef = useRef(null);
    const secondHandRef = useRef(null);

    useEffect(() => {
        fetchMeetings();
        const interval = setInterval(autoDeletePastMeetings, 60000); // Check every minute
        const clockInterval = setInterval(updateClock, 1000); // Update clock every second

        return () => {
            clearInterval(interval);
            clearInterval(clockInterval);
        };
    }, []);

    const updateClock = () => {
        const now = new Date();
        const hours = now.getHours() % 12;
        const minutes = now.getMinutes();
        const seconds = now.getSeconds();

        const hourDegrees = (hours / 12) * 360 + (minutes / 60) * 30;
        const minuteDegrees = (minutes / 60) * 360 + (seconds / 60) * 6;
        const secondDegrees = (seconds / 60) * 360;

        if (hourHandRef.current) hourHandRef.current.style.transform = `rotate(${hourDegrees}deg)`;
        if (minuteHandRef.current) minuteHandRef.current.style.transform = `rotate(${minuteDegrees}deg)`;
        if (secondHandRef.current) secondHandRef.current.style.transform = `rotate(${secondDegrees}deg)`;
    };

    const fetchMeetings = async () => {
        try {
            const response = await fetch('https://pilotserver.vercel.app/api/v1/hr/meetings', {
                headers: {
                    'Authorization': `Bearer ${localStorage.getItem('token')}`
                }
            });
            if (response.ok) {
                const meetings = await response.json();
                setScheduledMeetings(meetings);
            }
        } catch (error) {
            console.error('Error fetching meetings:', error);
        }
    };

    const autoDeletePastMeetings = () => {
        const now = new Date();
        setScheduledMeetings(prevMeetings => 
            prevMeetings.filter(meeting => new Date(meeting.date) > now)
        );
    };

    const handleScheduleMeeting = async () => {
        if (!meeting.title || !meeting.date || !meeting.attendees) {
            setError("All fields are required");
            return;
        }

        setLoading(true);
        setError(null);
        setSuccess(false);

        try {
            const token = localStorage.getItem('token');
            if (!token) {
                throw new Error('No authentication token found');
            }

            const localDate = new Date(meeting.date);
            const utcDate = new Date(localDate.getTime() - localDate.getTimezoneOffset() * 60000);

            const response = await fetch(`https://pilotserver.vercel.app/api/v1/hr/meetings`, {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                    'Authorization': `Bearer ${token}`
                },
                body: JSON.stringify({...meeting, date: utcDate.toISOString()})
            });

            if (!response.ok) {
                const errorData = await response.json();
                throw new Error(errorData.error || 'Failed to schedule meeting');
            }

            const data = await response.json();
            setScheduledMeetings(prevMeetings => [...prevMeetings, data]);
            setOpen(false);
            setSuccess(true);
            setMeeting({ title: '', date: '', attendees: '', notes: '' });
        } catch (error) {
            setError(error.message);
        } finally {
            setLoading(false);
        }
    };

    return (
        <div className="meeting-scheduler">
            <div className="clock">
                <div className="clock-face">
                    {[...Array(12)].map((_, i) => (
                        <div key={i} className="hour-marker" style={{transform: `rotate(${i * 30}deg)`}}>
                            <div className="marker-line"></div>
                        </div>
                    ))}
                    <div ref={hourHandRef} className="hand hour-hand"></div>
                    <div ref={minuteHandRef} className="hand minute-hand"></div>
                    <div ref={secondHandRef} className="hand second-hand"></div>
                    <div className="center-dot"></div>
                </div>
            </div>

            <Button variant="contained" onClick={() => setOpen(true)}>Schedule Meeting</Button>
            {error && <Alert severity="error">{error}</Alert>}
            {success && <Alert severity="success">Meeting scheduled successfully</Alert>}
            
            <List>
                {scheduledMeetings.map((scheduledMeeting, index) => {
                    const utcDate = new Date(scheduledMeeting.date);
                    const istDate = new Date(utcDate.getTime() + (5.5 * 60 * 60 * 1000)); // Convert to IST
                    return (
                        <ListItem key={index}>
                            <ListItemText
                                primary={scheduledMeeting.title}
                                secondary={`Date: ${istDate.toLocaleString('en-IN', { timeZone: 'Asia/Kolkata' })}, Attendees: ${scheduledMeeting.attendees}`}
                            />
                        </ListItem>
                    );
                })}
            </List>

            <Dialog open={open} onClose={() => setOpen(false)}>
                <DialogTitle>Schedule New Meeting</DialogTitle>
                <DialogContent>
                    <TextField 
                        label="Title" 
                        fullWidth 
                        margin="normal"
                        value={meeting.title} 
                        onChange={(e) => setMeeting({...meeting, title: e.target.value})} 
                    />
                    <TextField 
                        type="datetime-local"
                        label="Date"
                        fullWidth
                        margin="normal"
                        value={meeting.date}
                        onChange={(e) => setMeeting({...meeting, date: e.target.value})}
                        InputLabelProps={{
                            shrink: true,
                        }}
                    />
                    <TextField 
                        label="Attendees" 
                        fullWidth 
                        margin="normal"
                        value={meeting.attendees} 
                        onChange={(e) => setMeeting({...meeting, attendees: e.target.value})} 
                    />
                    <TextField 
                        label="Notes" 
                        fullWidth 
                        margin="normal"
                        multiline
                        rows={4}
                        value={meeting.notes} 
                        onChange={(e) => setMeeting({...meeting, notes: e.target.value})} 
                    />
                </DialogContent>
                <DialogActions>
                    <Button onClick={() => setOpen(false)} color="primary">
                        Cancel
                    </Button>
                    <Button onClick={handleScheduleMeeting} color="primary" disabled={loading}>
                        {loading ? <CircularProgress size={24} /> : 'Schedule'}
                    </Button>
                </DialogActions>
            </Dialog>
        </div>
    );
};

export default MeetingScheduler;
