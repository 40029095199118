import React, { useEffect } from 'react';
import Header from '../components/Header';
import Services from '../components/Services';
import Banners from '../components/Banners';
import '../styles/Home.css'; // Make sure this path is correct
// import '../scripts/cursor'; // Assuming you've created this file for the custom cursor

const Home = () => {
    useEffect(() => {
        // Scroll reveal effect
        const observer = new IntersectionObserver((entries) => {
            entries.forEach(entry => {
                if (entry.isIntersecting) {
                    entry.target.classList.add('active');
                }
            });
        }, { threshold: 0.1 });

        document.querySelectorAll('.scroll-reveal').forEach(el => observer.observe(el));

        // Cleanup
        return () => {
            document.querySelectorAll('.scroll-reveal').forEach(el => observer.unobserve(el));
        };
    }, []);

    return (
        <div>
            <div className="custom-cursor"></div>
            
            <Header />
            
            {/* <section className="parallax-section">
                <h2 className="text-gradient">Welcome to Our Website</h2>
            </section> */}
            
            <Banners />
            
            <div className="scroll-reveal">
                <Services />
            </div>
            
            {/* <div className="asymmetrical-grid">
                <div className="grid-item-large glass-morphism">
                    <h3>Large Grid Item</h3>
                    <p>This is a large grid item with glass morphism effect.</p>
                </div>
                <div className="grid-item-small">
                    <button className="interactive-element">Interactive Button</button>
                </div>
            </div> */}

            {/* <Portfolio /> */}
            {/* <Footer /> */}
        </div>
    );
}

export default Home;