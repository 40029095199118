import React, { useState, useEffect } from 'react';
import axios from 'axios';
import {
    Check, X, Search, FileText, Shield,
    Download, Copy, Loader
} from 'lucide-react';
import '../styles/DocumentVerification.css';

const VerificationButton = ({ handleVerification, isVerifying, docId }) => {
    const handleKeyDown = (event) => {
        if (event.key === 'Enter' && (docId !== undefined && docId !== null)) {
            handleVerification();
        }
    };

    useEffect(() => {
        // Add keydown event listener
        window.addEventListener('keydown', handleKeyDown);

        // Cleanup event listener on component unmount
        return () => {
            window.removeEventListener('keydown', handleKeyDown);
        };
    }, [docId]); // Re-run effect if docId changes

    return (
        <button
            onClick={handleVerification}
            disabled={isVerifying || !docId}
            className="verify-btn"
            aria-busy={isVerifying}
            tabIndex={0} // Make button focusable
        >
            {isVerifying ? (
                <Loader className="spinner" />
            ) : (
                <>
                    <Search />
                    Verify
                </>
            )}
        </button>
    );
};

const DocumentVerification = () => {
    const [docId, setDocId] = useState('');
    const [isVerifying, setIsVerifying] = useState(false);
    const [verificationStatus, setVerificationStatus] = useState(null);
    const [showConfetti, setShowConfetti] = useState(false);
    const [recentSearches, setRecentSearches] = useState([]);
    const [copied, setCopied] = useState(false);
    const [activeTab, setActiveTab] = useState('details');
    const [error, setError] = useState('');
    const [document, setDocument] = useState(null);
    const [verificationHistory, setVerificationHistory] = useState([]);
    const [particles, setParticles] = useState([]);

    const API_URL = process.env.REACT_APP_API_URL;

    // Effect for celebration particles
    useEffect(() => {
        if (showConfetti) {
            const initialParticles = Array.from({ length: 50 }, (_, i) => ({
                id: i,
                x: Math.random() * window.innerWidth,
                y: -20, // Start above the viewport
                size: Math.random() * 8 + 4,
                color: ['#FFD700', '#FFA500', '#FF6347', '#87CEEB', '#98FB98'][Math.floor(Math.random() * 5)],
                velocity: {
                    x: (Math.random() - 0.5) * 8,
                    y: Math.random() * 3 + 2
                }
            }));
            setParticles(initialParticles);

            // Cleanup
            return () => setParticles([]);
        }
    }, [showConfetti]);

    // Animation loop for particles
    useEffect(() => {
        if (particles.length > 0) {
            const animationFrame = requestAnimationFrame(() => {
                setParticles(currentParticles =>
                    currentParticles.map(particle => ({
                        ...particle,
                        y: particle.y + particle.velocity.y,
                        x: particle.x + particle.velocity.x,
                    })).filter(particle => particle.y < window.innerHeight)
                );
            });

            return () => cancelAnimationFrame(animationFrame);
        }
    }, [particles]);

    useEffect(() => {
        if (copied) {
            const timer = setTimeout(() => setCopied(false), 2000);
            return () => clearTimeout(timer);
        }
    }, [copied]);

    const handleVerification = async () => {
        if (!docId) {
            setError('Document ID cannot be empty');
            return;
        }

        setIsVerifying(true);
        setError('');

        try {
            const response = await axios.get(`https://pilotserver.vercel.app/api/v1/documents/verify/${docId}`);
            
            if (response.data.success) {
                const doc = response.data.document;
                setDocument(doc);
                setVerificationStatus(true);
                setShowConfetti(true);
                setTimeout(() => setShowConfetti(false), 3000);

                // Fetch verification history
                setVerificationHistory(doc.verificationHistory || []);

                // Update recent searches
                setRecentSearches(prev => {
                    const newSearches = [docId, ...prev.filter(id => id !== docId)].slice(0, 5);
                    return newSearches;
                });
            } else {
                setVerificationStatus(false);
                setError('Document not found');
            }
        } catch (err) {
            setVerificationStatus(false);
            setError(err.response?.data?.message || 'Error verifying document');
        } finally {
            setIsVerifying(false);
        }
    };

    const copyToClipboard = (text) => {
        navigator.clipboard.writeText(text);
        setCopied(true);
    };

    return (
        <div className="doc-verification-container">
            {/* Celebration Effect */}
            {particles.length > 0 && (
                <div style={{ 
                    position: 'fixed', 
                    top: 0, 
                    left: 0, 
                    width: '100%', 
                    height: '100%', 
                    pointerEvents: 'none',
                    zIndex: 1000 
                }}>
                    {particles.map(particle => (
                        <div
                            key={particle.id}
                            style={{
                                position: 'absolute',
                                left: particle.x + 'px',
                                top: particle.y + 'px',
                                width: particle.size + 'px',
                                height: particle.size + 'px',
                                backgroundColor: particle.color,
                                borderRadius: '50%',
                                transform: 'translate(-50%, -50%)',
                                opacity: 0.8,
                                transition: 'opacity 0.3s ease-out'
                            }}
                        />
                    ))}
                </div>
            )}

            <div className="doc-verification-header">
                <Shield />
                <h1>Document Verification Portal</h1>
                <p>
                    Pilomation secure and fast document verification system for businesses and organizations.
                </p>
            </div>

            <div className="doc-verification-main-content">
                <div className="doc-search-card">
                    <div className="doc-search-box">
                        <div className="doc-search-input-wrapper">
                            <Search />
                            <input
                                type="text"
                                placeholder="Enter Document ID (e.g., DOC-123)"
                                value={docId}
                                onChange={(e) => setDocId(e.target.value.toUpperCase())}
                                aria-label="Document ID"
                            />
                        </div>
                        <VerificationButton
                            handleVerification={handleVerification}
                            isVerifying={isVerifying}
                            docId={docId}
                        />
                    </div>
                    {error && <p className="error-message">{error}</p>}
                    {recentSearches.length > 0 && (
                        <div className="doc-recent-searches">
                            <p>Recent verifications:</p>
                            <div className="recent-search-list">
                                {recentSearches.map((id) => (
                                    <button key={id} onClick={() => setDocId(id)}>
                                        <FileText /> {id}
                                    </button>
                                ))}
                            </div>
                        </div>
                    )}
                </div>

                {verificationStatus !== null && (
                    <div className="doc-results-section">
                        <div className="doc-status-header">
                            <div className="status-icon">
                                {verificationStatus ? <Check /> : <X />}
                            </div>
                            <div className="status-text">
                                <h2>{verificationStatus ? 'Valid Document' : 'Invalid Document'}</h2>
                                <p>{verificationStatus ? 'This document is verified' : 'Document not found'}</p>
                            </div>
                        </div>

                        {verificationStatus && document && (
                            <div className="doc-tab-section">
                                <div className="doc-tabs">
                                    {['details', 'history', 'security'].map((tab) => (
                                        <button
                                            key={tab}
                                            onClick={() => setActiveTab(tab)}
                                            className={`doc-tab-btn ${activeTab === tab ? 'active' : ''}`}
                                            aria-pressed={activeTab === tab}
                                        >
                                            {tab.charAt(0).toUpperCase() + tab.slice(1)}
                                        </button>
                                    ))}
                                </div>

                                <div className="doc-tab-content">
                                    {activeTab === 'details' && (
                                        <div className="doc-details">
                                            <h3>Document Details</h3>
                                            {Object.entries(document).map(([key, value]) => (
                                                <div className="doc-detail-item" key={key}>
                                                    <span>{key.replace(/([A-Z])/g, ' $1')}</span>
                                                    <span>
                                                        {key === 'lastVerified' && value && typeof value === 'object'
                                                            ? value.comment
                                                            : typeof value === 'string'
                                                            ? value
                                                            : JSON.stringify(value)}
                                                    </span>
                                                </div>
                                            ))}
                                        </div>
                                    )}

                                    {activeTab === 'history' && (
                                        <div className="doc-history">
                                            <h3>Verification History</h3>
                                            {verificationHistory.length > 0 ? (
                                                <table className="doc-history-table">
                                                    <thead>
                                                        <tr>
                                                            <th>Date</th>
                                                            <th>Verifier</th>
                                                            <th>Comment</th>
                                                        </tr>
                                                    </thead>
                                                    <tbody>
                                                        {verificationHistory.map((record, index) => (
                                                            <tr key={index}>
                                                                <td>{new Date(record.verifiedAt).toLocaleDateString()}</td>
                                                                <td>{record.verifiedBy}</td>
                                                                <td>{record.comment}</td>
                                                            </tr>
                                                        ))}
                                                    </tbody>
                                                </table>
                                            ) : (
                                                <p>No verification history available.</p>
                                            )}
                                        </div>
                                    )}

                                    {activeTab === 'security' && document.securityHash && (
                                        <div className="doc-security">
                                            <h3>Security Information</h3>
                                            <div className="doc-security-item">
                                                <label>Security Hash</label>
                                                <code>{document.securityHash}</code>
                                            </div>
                                            <button
                                                onClick={() => copyToClipboard(document.securityHash)}
                                                className="copy-btn"
                                            >
                                                {copied ? 'Copied!' : 'Copy Hash'}
                                            </button>
                                            {document.qrCode && (
                                                <div className="doc-qr-section">
                                                    <img src="qr-code-placeholder.png" alt="QR Code" />
                                                </div>
                                            )}
                                        </div>
                                    )}
                                </div>
                            </div>
                        )}
                    </div>
                )}
            </div>
        </div>
    );
};

export default DocumentVerification;