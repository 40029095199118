import React, { useState } from 'react';
import { Link } from 'react-router-dom';
import '../styles/PrivacyPolicy.css'; // We'll create this next

const PrivacyPolicy = () => {
    const [activeSection, setActiveSection] = useState(null);

    const policyData = [
        {
            title: "Introduction",
            content: "Pilomation ('we', 'our', or 'us') is committed to protecting your privacy. This Privacy Policy explains how we collect, use, disclose, and safeguard your information when you use our software solutions and visit our website."
        },
        {
            title: "Information We Collect",
            content: "We collect personal information that you provide directly to us, such as your name, email address, and company information. We also automatically collect certain information about your device and how you interact with our services."
        },
        {
            title: "How We Use Your Information",
            content: "We use the information we collect to provide, maintain, and improve our services, to communicate with you, to comply with legal obligations, and to protect our rights and the rights of others."
        },
        {
            title: "Sharing of Your Information",
            content: "We may share your information with third-party service providers who perform services on our behalf, with your consent, or as required by law. We do not sell your personal information."
        },
        {
            title: "Data Security",
            content: "We implement appropriate technical and organizational measures to protect the security of your personal information. However, no method of transmission over the Internet is 100% secure."
        },
        {
            title: "Your Rights and Choices",
            content: "Depending on your location, you may have certain rights regarding your personal information, including the right to access, correct, or delete your data. Contact us to exercise these rights."
        },
        {
            title: "Changes to This Privacy Policy",
            content: "We may update this Privacy Policy from time to time. We will notify you of any changes by posting the new Privacy Policy on this page and updating the 'Last Updated' date."
        },
        {
            title: "Contact Us",
            content: "If you have any questions about this Privacy Policy, please contact us at privacy@pilomation.com."
        }
    ];

    const toggleSection = (index) => {
        setActiveSection(activeSection === index ? null : index);
    };

    return (
        <div className="privacy-policy-container">
            <h1>Pilomation Privacy Policy</h1>
            <p className="last-updated">Last Updated: June 28, 2024</p>

            <div className="policy-sections">
                {policyData.map((section, index) => (
                    <div key={index} className="policy-section">
                        <h2 onClick={() => toggleSection(index)}>
                            {section.title}
                            <span className={`arrow ${activeSection === index ? 'up' : 'down'}`}>
                                &#9650;
                            </span>
                        </h2>
                        {activeSection === index && <p>{section.content}</p>}
                    </div>
                ))}
            </div>

            <div className="contact-form">
                <h2>Have questions about our privacy policy?</h2>
                <p>Contact our data protection team:</p>
                <Link to="/contact" className="contact-button">
                    Contact Us
                </Link>
            </div>
        </div>
    );
};

export default PrivacyPolicy;