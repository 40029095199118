import React from 'react';
import '../styles/BlogPage.css';
import logo from '../assets/logo.png';
import teamImage from '../assets/value1.jpg';

const Culture = () => {
    return (
        <div className="page culture-page">
            <header className="culture-header">
                <img src={logo} alt="Company Logo" className="logo" />
                <div className="header-text">
                    <h1>Our Culture at TechSolutions Inc.</h1>
                    <p>Discover what makes TechSolutions a great place to work!</p>
                </div>
            </header>

            <div className="culture-content">
                <div className="image-container">
                    <img src={teamImage} alt="Team Working" className="culture-image" />
                </div>
                <div className="text-container">
                    <h2>Empowered Teams</h2>
                    <p>
                        At TechSolutions, we empower our teams to innovate and collaborate. We believe in fostering an environment where every team member's voice is heard and valued.
                    </p>
                </div>
            </div>

            <div className="culture-content">
                <div className="text-container">
                    <h2>Diversity and Inclusion</h2>
                    <p>
                        Diversity is at the core of our company. We celebrate different perspectives, backgrounds, and ideas, which contribute to our innovation and success.
                    </p>
                </div>
                <div className="image-container">
                    <img src={teamImage} alt="Team Diversity" className="culture-image" />
                </div>
            </div>

            <div className="culture-content">
                <div className="image-container">
                    <img src={teamImage} alt="Team Collaboration" className="culture-image" />
                </div>
                <div className="text-container">
                    <h2>Continuous Learning</h2>
                    <p>
                        Learning never stops at TechSolutions. We encourage continuous growth and provide opportunities for professional development to our employees.
                    </p>
                </div>
            </div>

            <footer className="culture-footer">
                <img src={logo} alt="Company Logo" className="footer-logo" />
                <p>© 2024 TechSolutions Inc.</p>
            </footer>
        </div>
    );
};

export default Culture;
