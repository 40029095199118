import React, { useState, useEffect } from 'react';
import { Link, useNavigate } from 'react-router-dom';
import { FaUsers, FaProjectDiagram, FaCog, FaSignOutAlt, FaChartBar, FaCalendarAlt, FaBell, FaCaretDown } from 'react-icons/fa';
import '../styles/Dashboard.css';

const MNCsEmployeesDashboard = () => {
    const [userData, setUserData] = useState(null);
    const [loading, setLoading] = useState(true);
    const [error, setError] = useState(null);
    const [showQuickActions, setShowQuickActions] = useState(false);
    const navigate = useNavigate();

    useEffect(() => {
        fetchUserData();
    }, []);

    const fetchUserData = async () => {
        const token = localStorage.getItem('token');
        try {
            const response = await fetch('https://pilotserver.vercel.app/api/v1/users/data', { // Ensure the URL matches your backend route
                method: 'GET',
                headers: {
                    'Authorization': `Bearer ${token}`
                }
            });
            if (!response.ok) {
                throw new Error('Failed to fetch user data');
            }
            const data = await response.json();
            setUserData(data);
        } catch (err) {
            setError(err.message);
        } finally {
            setLoading(false);
        }
    };

    const handleLogout = () => {
        localStorage.removeItem('token');
        navigate('/login');
    };

    const handleQuickAction = (action) => {
        setShowQuickActions(false);
        switch(action) {
            case 'timesheet':
                navigate('/dashboard/timesheet');
                break;
            case 'leave':
                navigate('/dashboard/leave-request');
                break;
            case 'training':
                navigate('/dashboard/training');
                break;
            default:
                break;
        }
    };

    if (loading) return <div className="loading">Loading...</div>;
    if (error) return <div className="error">{error}</div>;

    return (
        <div className="dashboard-container">
            <header className="dashboard-header">
                <h1>Welcome to Pilomation Employees Dashboard</h1>
                <div className="user-info">
                    <span>Welcome, {userData?.firstName} {userData?.lastName}</span>
                    <button onClick={handleLogout} className="logout-btn">
                        <FaSignOutAlt /> Logout
                    </button>
                </div>
            </header>
            <div className="dashboard-content">
                <nav className="dashboard-nav">
                    <Link to="/dashboard/employees" className="nav-item"><FaUsers />Your Team</Link>
                    <Link to="/dashboard/projects" className="nav-item"><FaProjectDiagram /> Projects</Link>
                    <Link to="/dashboard/reports" className="nav-item"><FaChartBar /> Reports</Link>
                    <Link to="/dashboard/calendar" className="nav-item"><FaCalendarAlt /> Calendar</Link>
                    <Link to="/dashboard/notifications" className="nav-item"><FaBell /> Notifications</Link>
                    <Link to="/dashboard/settings" className="nav-item"><FaCog /> Settings</Link>
                </nav>
                <main className="dashboard-main">
                    <h2><span>Hello, {userData?.firstName} {userData?.lastName}</span></h2>
                    <div className="dashboard-widgets">
                        <div className="widget clickable" onClick={() => navigate('/dashboard/mncs')}>
                            <h3>Your MNC</h3>
                            <p className="widget-value">{userData?.mnc || 'Not Assigned'}</p>
                        </div>
                        <div className="widget clickable" onClick={() => navigate('/dashboard/departments')}>
                            <h3>Your Department</h3>
                            <p className="widget-value">{userData?.department || 'Not Assigned'}</p>
                        </div>
                        <div className="widget clickable" onClick={() => navigate('/dashboard/projects')}>
                            <h3>Your Active Projects</h3>
                            <p className="widget-value">{userData?.activeProjects || 0}</p>
                        </div>
                        <div className="widget clickable" onClick={() => navigate('/dashboard/tasks')}>
                            <h3>Pending Tasks</h3>
                            <p className="widget-value">{userData?.pendingTasks || 0}</p>
                        </div>
                    </div>
                    <div className="quick-actions">
                        <h3>Quick Actions</h3>
                        <div className="dropdown">
                            <button onClick={() => setShowQuickActions(!showQuickActions)} className="dropdown-toggle">
                                Select Action <FaCaretDown />
                            </button>
                            {showQuickActions && (
                                <ul className="dropdown-menu">
                                    <li onClick={() => handleQuickAction('timesheet')}>Submit Timesheet</li>
                                    <li onClick={() => handleQuickAction('leave')}>Request Leave</li>
                                    <li onClick={() => handleQuickAction('training')}>View Training</li>
                                </ul>
                            )}
                        </div>
                    </div>
                    <div className="recent-activities">
                        <h3>Recent Activities</h3>
                        <ul>
                            <li>Completed project milestone for "AI Integration"</li>
                            <li>Submitted timesheet for last week</li>
                            <li>Attended team meeting on product roadmap</li>
                            <li>Completed annual compliance training</li>
                        </ul>
                    </div>
                </main>
            </div>
        </div>
    );
};

export default MNCsEmployeesDashboard;
