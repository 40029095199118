import React from 'react';
import '../styles/CookiesPolicy.css';

const CookiesPolicy = () => {
    return (
        <div className="cookies-policy-container">
            <h1>Pilomation Cookies Policy</h1>
            <p className="last-updated"> Last Updated: June 28, 2024</p>

            <div className="policy-section">
                <h2>Introduction</h2>
                <p>
                    At Pilomation, we are committed to protecting your privacy and ensuring transparency about how we use cookies. This Cookies Policy explains what cookies are, how we use them, the types of cookies we use, and how you can manage your cookie preferences.
                </p>
            </div>

            <div className="policy-section">
                <h2>What Are Cookies?</h2>
                <p>
                    Cookies are small text files that are placed on your device when you visit our website. They help us to provide you with a better user experience by remembering your preferences and providing insights into how our website is being used. Cookies can be "session cookies" which are deleted when you close your browser, or "persistent cookies" which remain on your device until they expire or are deleted.
                </p>
            </div>

            <div className="policy-section">
                <h2>Types of Cookies We Use</h2>
                <p>
                    <strong>1. Necessary Cookies</strong><br />
                    These cookies are essential for the website to function properly. They enable basic functionalities such as page navigation, secure access to certain parts of the website, and ensure the website's security. Without these cookies, some parts of the website may not work as intended.
                </p>
                <p>
                    <strong>2. Performance Cookies</strong><br />
                    These cookies collect information about how visitors use our website, such as which pages are most visited and any error messages encountered. This data helps us improve the performance and design of our website, providing you with a better browsing experience. Performance cookies do not collect information that identifies individual visitors.
                </p>
                <p>
                    <strong>3. Functionality Cookies</strong><br />
                    Functionality cookies allow our website to remember choices you make, such as your username, language, or region, and provide enhanced, personalized features. These cookies can also be used to remember changes you have made to text size, fonts, and other parts of web pages that you can customize. The information these cookies collect may be anonymized and they cannot track your browsing activity on other websites.
                </p>
                <p>
                    <strong>4. Targeting/Advertising Cookies</strong><br />
                    These cookies are used to deliver advertisements that are relevant to you and your interests. They are also used to limit the number of times you see an advertisement and help measure the effectiveness of advertising campaigns. Targeting cookies are usually placed by advertising networks with our permission. They remember that you have visited our website and this information is shared with other organizations such as advertisers.
                </p>
            </div>

            <div className="policy-section">
                <h2>How We Use Cookies</h2>
                <p>
                    - <strong>Analytics</strong>: To analyze how users interact with our website and to improve its functionality and user experience.<br />
                    - <strong>Personalization</strong>: To remember your preferences and settings, such as language and location.<br />
                    - <strong>Advertising</strong>: To show you relevant advertisements based on your browsing behavior.<br />
                    - <strong>Security</strong>: To ensure the security of our website and protect against fraud and abuse.
                </p>
            </div>

            <div className="policy-section">
                <h2>Managing Cookies</h2>
                <p>
                    You have the right to manage your cookie preferences. You can do this by adjusting your browser settings to block or delete cookies. Please note that if you disable certain cookies, some parts of our website may not function correctly.
                </p>
                <p>
                    To learn more about how to manage cookies on popular browsers, you can visit the following links:
                </p>
                <ul>
                    <li><a href="https://support.google.com/chrome/answer/95647?hl=en" target="_blank" rel="noopener noreferrer">Google Chrome</a></li>
                    <li><a href="https://support.mozilla.org/en-US/kb/enable-and-disable-cookies-website-preferences" target="_blank" rel="noopener noreferrer">Mozilla Firefox</a></li>
                    <li><a href="https://support.apple.com/guide/safari/manage-cookies-and-website-data-sfri11471/mac" target="_blank" rel="noopener noreferrer">Apple Safari</a></li>
                    <li><a href="https://support.microsoft.com/en-us/microsoft-edge/delete-cookies-in-microsoft-edge-63947406-40ac-c3b8-57b9-2a946a29ae09" target="_blank" rel="noopener noreferrer">Microsoft Edge</a></li>
                </ul>
            </div>

            <div className="policy-section">
                <h2>Changes to This Cookie Policy</h2>
                <p>
                    We may update this Cookies Policy from time to time to reflect changes in our practices or for other operational, legal, or regulatory reasons. Any changes will be posted on this page with an updated "Last Updated" date.
                </p>
            </div>

            <div className="policy-section">
                <h2>Contact Us</h2>
                <p>
                    If you have any questions or concerns about our use of cookies, please contact us at:
                </p>
                <p>
                    <strong>Pilomation</strong><br />
                    Email: <a href="mailto:support@pilomation.com">support@pilomation.com</a>
                </p>
            </div>
        </div>
    );
}

export default CookiesPolicy;
