import React, { useState, useEffect, useCallback, useMemo } from 'react';
import { Link, useNavigate } from 'react-router-dom';
import { 
  FaUsers, FaProjectDiagram, FaCog, FaSignOutAlt, 
  FaCalendarAlt, FaCaretDown, FaDatabase, FaUserPlus, 
  FaEdit, FaLayerGroup, FaSearch, FaBell, FaChartLine
} from 'react-icons/fa';
import '../styles/AdminDashboard.css';

const API_BASE_URL = 'https://pilotserver.vercel.app';

const AdminDashboard = () => {
    const [adminData, setAdminData] = useState(null);
    const [loading, setLoading] = useState(true);
    const [error, setError] = useState(null);
    const [showQuickActions, setShowQuickActions] = useState(false);
    const [searchTerm, setSearchTerm] = useState('');
    const navigate = useNavigate();

    const handleLogout = useCallback(() => {
        localStorage.removeItem('token');
        localStorage.removeItem('userRole');
        navigate('/login');
    }, [navigate]);

    const fetchAdminData = useCallback(async () => {
        const token = localStorage.getItem('token');
        if (!token) {
            setError('No authentication token found');
            setLoading(false);
            return;
        }

        try {
            console.log(`Fetching from: ${API_BASE_URL}/api/v1/admin/data`);
            const response = await fetch(`${API_BASE_URL}/api/v1/admin/data`, {
                method: 'GET',
                headers: {
                    'Authorization': `Bearer ${token}`,
                    'Content-Type': 'application/json'
                }
            });

            if (response.status === 401) {
                throw new Error('Unauthorized access');
            }

            if (!response.ok) {
                const errorData = await response.json();
                throw new Error(errorData.message || 'Failed to fetch admin data');
            }

            const data = await response.json();
            console.log('Received admin data:', data);
            setAdminData(data);
        } catch (err) {
            console.error("Error fetching admin data:", err);
            setError(err.message);
            if (err.message === 'Unauthorized access') {
                handleLogout();
            }
        } finally {
            setLoading(false);
        }
    }, [handleLogout]);

    useEffect(() => {
        const userRole = localStorage.getItem('userRole');
        if (userRole !== 'admin') {
            navigate('/login');
        } else {
            fetchAdminData();
        }
    }, [navigate, fetchAdminData]);

    const handleQuickAction = useCallback((action) => {
        setShowQuickActions(false);
        switch(action) {
            case 'addUser':
                navigate('/admin/add-user');
                break;
            case 'modifyUser':
                navigate('/admin/modify-user');
                break;
            case 'assignProject':
                navigate('/admin/assign-project');
                break;
            default:
                break;
        }
    }, [navigate]);

    const filteredActivities = useMemo(() => {
        const activities = [
            'New user "John Doe" added to the system',
            'Project "AI Integration" assigned to Team Alpha',
            'User permissions updated for Marketing Department',
            'System backup completed successfully',
            'New department "R&D" created',
            'Performance review cycle initiated'
        ];
        return activities.filter(activity => 
            activity.toLowerCase().includes(searchTerm.toLowerCase())
        );
    }, [searchTerm]);

    if (loading) return <div className="loading">Loading...</div>;
    if (error) return <div className="error">Error: {error}</div>;

    return (
        <div className="admin-dashboard-container">
            <Header adminData={adminData} handleLogout={handleLogout} />
            <div className="admin-dashboard-content">
                <Sidebar />
                <main className="admin-dashboard-main">
                    <h2>Admin Control Panel</h2>
                    <DashboardWidgets adminData={adminData} />
                    <div className="dashboard-sections">
                        <QuickActions 
                            showQuickActions={showQuickActions} 
                            setShowQuickActions={setShowQuickActions} 
                            handleQuickAction={handleQuickAction} 
                        />
                        <RecentActivities 
                            activities={filteredActivities}
                            searchTerm={searchTerm}
                            setSearchTerm={setSearchTerm}
                        />
                    </div>
                </main>
            </div>
        </div>
    );
};

const Header = ({ adminData, handleLogout }) => (
    <header className="admin-dashboard-header">
        <h1>Pilomation Admin Dashboard</h1>
        <div className="admin-info">
            <span>Welcome, Admin {adminData?.firstName} {adminData?.lastName}</span>
            <button onClick={handleLogout} className="logout-btn">
                <FaSignOutAlt /> Logout
            </button>
        </div>
    </header>
);

const Sidebar = () => (
    <aside className="admin-dashboard-sidebar">
        <nav>
            <ul>
                <li><Link to="/admin/dashboard"><FaCog /> Dashboard</Link></li>
                <li><Link to="/admin/users"><FaUsers /> Users</Link></li>
                <li><Link to="/admin/projects"><FaProjectDiagram /> Projects</Link></li>
                <li><Link to="/admin/departments"><FaLayerGroup /> Departments</Link></li>
                <li><Link to="/admin/settings"><FaDatabase /> Settings</Link></li>
            </ul>
        </nav>
    </aside>
);

const DashboardWidgets = ({ adminData }) => (
    <section className="dashboard-widgets">
        <div className="widget">
            <div className="widget-icon"><FaUsers /></div>
            <div className="widget-details">
                <span>Total Users</span>
                <strong>{adminData?.totalUsers}</strong>
            </div>
        </div>
        <div className="widget">
            <div className="widget-icon"><FaProjectDiagram /></div>
            <div className="widget-details">
                <span>Active Projects</span>
                <strong>{adminData?.activeProjects}</strong>
            </div>
        </div>
        <div className="widget">
            <div className="widget-icon"><FaBell /></div>
            <div className="widget-details">
                <span>System Alerts</span>
                <strong>{adminData?.systemAlerts}</strong>
            </div>
        </div>
        <div className="widget">
            <div className="widget-icon"><FaLayerGroup /></div>
            <div className="widget-details">
                <span>Total Departments</span>
                <strong>{adminData?.totalDepartments}</strong>
            </div>
        </div>
    </section>
);

const QuickActions = ({ showQuickActions, setShowQuickActions, handleQuickAction }) => (
    <section className="quick-actions">
        <button onClick={() => setShowQuickActions(!showQuickActions)} className="quick-actions-toggle">
            Quick Actions <FaCaretDown />
        </button>
        {showQuickActions && (
            <div className="quick-actions-menu">
                <button onClick={() => handleQuickAction('addUser')}><FaUserPlus /> Add User</button>
                <button onClick={() => handleQuickAction('modifyUser')}><FaEdit /> Modify User</button>
                <button onClick={() => handleQuickAction('assignProject')}><FaLayerGroup /> Assign Project</button>
            </div>
        )}
    </section>
);

const RecentActivities = ({ activities, searchTerm, setSearchTerm }) => (
    <section className="recent-activities">
        <h3>Recent Activities</h3>
        <div className="activity-search">
            <input
                type="text"
                placeholder="Search activities..."
                value={searchTerm}
                onChange={(e) => setSearchTerm(e.target.value)}
            />
            <FaSearch className="search-icon" />
        </div>
        <ul>
            {activities.map((activity, index) => (
                <li key={index}>{activity}</li>
            ))}
        </ul>
    </section>
);

export default AdminDashboard;
