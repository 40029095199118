import React from 'react';
import { Link } from 'react-router-dom';
import logo from '../assets/logo.png';
import additionalLogo from '../assets/favicon.png'; // Add your additional logo here
import '../styles/Home.css';
import Robot from './Robot';

const Header = () => {
    return (
        <header className="header">
            <div className="header-left">
                <img src={additionalLogo} alt="Additional Logo" className="logo-left" />
            </div>
            <div className="header-content">
                <img src={logo} alt="Company Logo" className="logo" />
                <h1 className="title">Welcome to Pilomation</h1>
                <p className="tagline">Your Partner in Software Solutions</p>
                <Link to="/contact" className="cta-button">Get in Touch</Link>
            </div>
            <Robot />
        </header>
    );
}

export default Header;
