import React, { useState } from 'react';
import { FaChartBar, FaDownload, FaFilter, FaCalendarAlt } from 'react-icons/fa';
import { Line, Bar, Pie } from 'react-chartjs-2';
import DatePicker from 'react-datepicker';
import 'react-datepicker/dist/react-datepicker.css';
import '../styles/ReportsPage.css';

const ReportsPage = () => {
    const [startDate, setStartDate] = useState(new Date(new Date().setMonth(new Date().getMonth() - 1)));
    const [endDate, setEndDate] = useState(new Date());

    // Mock data for charts
    const lineChartData = {
        labels: ['Jan', 'Feb', 'Mar', 'Apr', 'May', 'Jun'],
        datasets: [
            {
                label: 'Revenue',
                data: [12, 19, 3, 5, 2, 3],
                borderColor: 'rgb(75, 192, 192)',
                tension: 0.1
            }
        ]
    };

    const barChartData = {
        labels: ['Project A', 'Project B', 'Project C', 'Project D'],
        datasets: [
            {
                label: 'Budget',
                data: [65, 59, 80, 81],
                backgroundColor: 'rgba(54, 162, 235, 0.6)'
            },
            {
                label: 'Actual Spend',
                data: [45, 79, 50, 41],
                backgroundColor: 'rgba(255, 99, 132, 0.6)'
            }
        ]
    };

    const pieChartData = {
        labels: ['Marketing', 'Development', 'Sales', 'Support'],
        datasets: [
            {
                data: [12, 19, 3, 5],
                backgroundColor: [
                    'rgba(255, 99, 132, 0.8)',
                    'rgba(54, 162, 235, 0.8)',
                    'rgba(255, 206, 86, 0.8)',
                    'rgba(75, 192, 192, 0.8)'
                ]
            }
        ]
    };

    return (
        <div className="page-container reports-page">
            <header className="page-header">
                <h1><FaChartBar /> Analytics Dashboard</h1>
                <div className="header-actions">
                    <button className="btn btn-primary"><FaDownload /> Export Reports</button>
                </div>
            </header>

            <div className="reports-controls">
                <div className="date-range-picker">
                    <FaCalendarAlt />
                    <DatePicker
                        selected={startDate}
                        onChange={(date) => setStartDate(date)}
                        selectsStart
                        startDate={startDate}
                        endDate={endDate}
                    />
                    <span>to</span>
                    <DatePicker
                        selected={endDate}
                        onChange={(date) => setEndDate(date)}
                        selectsEnd
                        startDate={startDate}
                        endDate={endDate}
                        minDate={startDate}
                    />
                </div>
                <button className="btn btn-secondary"><FaFilter /> Filter</button>
            </div>

            <div className="reports-grid">
                <div className="report-card full-width">
                    <h2>Revenue Over Time</h2>
                    <Line data={lineChartData} options={{ responsive: true, maintainAspectRatio: false }} />
                </div>
                <div className="report-card">
                    <h2>Project Budget vs. Actual</h2>
                    <Bar data={barChartData} options={{ responsive: true, maintainAspectRatio: false }} />
                </div>
                <div className="report-card">
                    <h2>Department Allocation</h2>
                    <Pie data={pieChartData} options={{ responsive: true, maintainAspectRatio: false }} />
                </div>
                <div className="report-card">
                    <h2>Key Metrics</h2>
                    <div className="metrics-grid">
                        <div className="metric">
                            <h3>Total Revenue</h3>
                            <p className="metric-value">$1,234,567</p>
                            <p className="metric-change positive">+12.3%</p>
                        </div>
                        <div className="metric">
                            <h3>New Customers</h3>
                            <p className="metric-value">1,234</p>
                            <p className="metric-change positive">+5.7%</p>
                        </div>
                        <div className="metric">
                            <h3>Customer Satisfaction</h3>
                            <p className="metric-value">4.8/5.0</p>
                            <p className="metric-change positive">+0.2</p>
                        </div>
                        <div className="metric">
                            <h3>Churn Rate</h3>
                            <p className="metric-value">2.1%</p>
                            <p className="metric-change negative">+0.3%</p>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default ReportsPage;