import React, { useState } from 'react';
import { Link } from 'react-router-dom';
import '../styles/Services.css';

const Services = () => {
    const services = [
        {
            id: 100,
            title: 'Cloud Services',
            description: 'Scalable cloud infrastructure solutions tailored to your business needs.',
            icon: 'fas fa-cloud',
            features: [
                'Flexible and secure cloud storage',
                'Customizable infrastructure solutions',
                '24/7 support and monitoring',
            ]
        },
        {
            id: 101,
            title: 'AI Solutions',
            description: 'Cutting-edge artificial intelligence solutions to drive innovation and efficiency.',
            icon: 'fas fa-brain',
            features: [
                'Machine learning algorithms',
                'Natural language processing',
                'Predictive analytics',
            ]
        },
        {
            id: 102,
            title: 'Network Security',
            description: 'Comprehensive network security solutions to protect your digital assets.',
            icon: 'fas fa-shield-alt',
            features: [
                'Firewall implementation and management',
                'Intrusion detection and prevention systems',
                'Vulnerability assessments and remediation',
            ]
        },
        {
            id: 103,
            title: 'Mobile App Development',
            description: 'End-to-end mobile application development services for iOS and Android platforms.',
            icon: 'fas fa-mobile-alt',
            features: [
                'Native and cross-platform app development',
                'UI/UX design and prototyping',
                'Backend integration and API development',
            ]
        },
        {
            id: 104,
            title: 'DevOps',
            description: 'Streamlined development and operations practices to accelerate software delivery.',
            icon: 'fas fa-cogs',
            features: [
                'Continuous integration and deployment (CI/CD)',
                'Infrastructure automation',
                'Containerization and orchestration',
            ]
        },
        {
            id: 105,
            title: 'Marketing Services',
            description: 'Strategic marketing solutions to enhance brand visibility and customer engagement.',
            icon: 'fas fa-bullhorn',
            features: [
                'Digital marketing campaigns',
                'SEO and SEM optimization',
                'Social media management',
            ]
        },
        {
            id: 106,
            title: 'Web Development',
            description: 'Customized web development services for responsive and interactive websites.',
            icon: 'fas fa-globe',
            features: [
                'Frontend and backend development',
                'Content management systems (CMS)',
                'E-commerce solutions',
            ]
        },
        {
            id: 107,
            title: 'AI/ML Solutions',
            description: 'Advanced AI and machine learning solutions to extract insights and optimize processes.',
            icon: 'fas fa-robot',
            features: [
                'Data modeling and analysis',
                'Predictive modeling and forecasting',
                'Natural language processing (NLP)',
            ]
        },
    ];

    const [activeService, setActiveService] = useState(null);

    const handleMouseEnter = (serviceId) => {
        setActiveService(serviceId);
    };

    const handleMouseLeave = () => {
        setActiveService(null);
    };

    return (
        <section className="services-section">
            <div className="services-container">
                <h2>Our Services</h2>
                <div className="service-cards">
                    {services.map(service => (
                        <Link
                            key={service.id}
                            to={`/services/${service.id}`}
                            className={`service-card ${activeService === service.id ? 'active' : ''}`}
                            onMouseEnter={() => handleMouseEnter(service.id)}
                            onMouseLeave={handleMouseLeave}
                        >
                            <div className="service-icon">
                                <i className={service.icon}></i>
                            </div>
                            <div className="service-info">
                                <h3>{service.title}</h3>
                                <p>{service.description}</p>
                                <ul className={`service-features ${activeService === service.id ? 'active' : ''}`}>
                                    {service.features.map((feature, index) => (
                                        <li key={index}>{feature}</li>
                                    ))}
                                </ul>
                                <a href="#cta" className="service-cta">Learn More</a>
                            </div>
                        </Link>
                    ))}
                </div>
            </div>
        </section>
    );
}

export default Services;
